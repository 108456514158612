import { FC } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { useSorting } from "@/shared/hooks";
import { numberFormatter } from "@/shared/utils";
import { EmptyData } from "@/components/EmptyData/EmptyData";

export const BeneficiariesList: FC<any> = ({
  beneficiaries,
  selectedBeneficiaries,
  setSelectedBeneficiaries,
}) => {
  const { t } = useTranslation();
  const { orderBy, sortingValue } = useSorting();

  const handleCheckboxChange = (isChecked: boolean, benefeciaryId: number) => {
    if (!isChecked) {
      setSelectedBeneficiaries((prev: number[]) => {
        return [...prev, benefeciaryId];
      });
    } else {
      setSelectedBeneficiaries((prev: number[]) => {
        const updated = prev.filter((el) => el !== benefeciaryId);
        return updated;
      });
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedBeneficiaries((prev) => {
        const updated = [...prev];
        beneficiaries.forEach((ben) => {
          if (!updated.includes(ben.id)) {
            updated.push(ben.id);
          }
        });

        return updated;
      });
    } else {
      setSelectedBeneficiaries((prev) => {
        let updated = [...prev];
        beneficiaries.forEach((ben) => {
          updated = updated.filter((id) => ben.id !== id);
        });
        return updated;
      });
    }
  };

  if (beneficiaries?.length === 0) return <EmptyData />;

  let isAllChecked = true;
  beneficiaries?.forEach((ben) => {
    if (selectedBeneficiaries.includes(ben.id)) {
      isAllChecked = isAllChecked && true;
    } else {
      isAllChecked = isAllChecked && false;
    }
  });
  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 1600, position: "relative" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={isAllChecked}
                  onChange={handleSelectAll}
                  size="small"
                />
              </TableCell>
              <TableCell>{t("ID_CODE")}</TableCell>
              <TableCell>{t("External ID")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Phone number")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("personalData__ageHijri")}
                >
                  {t("Hijri Age")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("personalData__ageHijri") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Job")}</TableCell>
              <TableCell>{t("Region")}</TableCell>
              <TableCell>{t("City")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("familyData__familyMembersCount")}
                >
                  {t("Number of family members")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("familyData__familyMembersCount") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Product")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("financialData__salary")}
                >
                  {t("Salary")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("financialData__salary") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Salary Bank")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("financialData__fundingAmount")}
                >
                  {t("Average Fund")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("financialData__fundingAmount") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Order Entity")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {beneficiaries?.map((beneficiary: any) => {
              const isChecked = selectedBeneficiaries.includes(beneficiary.id);

              return (
                <TableRow
                  key={beneficiary.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    background: isChecked ? "#f5f5f5" : "none",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleCheckboxChange(isChecked, beneficiary.id)
                  }
                >
                  <TableCell>
                    <Checkbox
                      sx={{ padding: 0 }}
                      checked={isChecked}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{beneficiary.code}</TableCell>
                  <TableCell>{beneficiary?.externalId}</TableCell>
                  <TableCell>{beneficiary?.name}</TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.mobile}</TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.age}</TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.job}</TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.region}</TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.city}</TableCell>
                  <TableCell>
                    {beneficiary?.beneficiaryData?.familyMember}
                  </TableCell>
                  <TableCell>{beneficiary?.beneficiaryData?.product}</TableCell>
                  <TableCell>
                    {numberFormatter(beneficiary?.beneficiaryData?.salary)}
                  </TableCell>
                  <TableCell>
                    {beneficiary?.beneficiaryData?.salaryBank}
                  </TableCell>
                  <TableCell>
                    {numberFormatter(beneficiary?.beneficiaryData?.avgFund)}
                  </TableCell>
                  <TableCell>
                    {beneficiary?.beneficiaryData?.destination}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
