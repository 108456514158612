import { gql } from "@apollo/client";

export const CREATE_SIGNED_URL_TASK = gql`
  mutation CreateSignedUrl($taskId: Int!, $fileExtension: String!) {
    signedUrl(taskId: $taskId, fileExtension: $fileExtension) {
      blobUrl
      signedUrl
    }
  }
`;
