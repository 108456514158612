import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Button } from "@mui/material";
import {
  ASSIGN_TO_PROJECT_MANAGER,
  REJECT_TASK,
  UPDATE_CS_TASK,
} from "@/shared/graphql/mutations";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import {
  fetchAllCitiesByRegionId,
  fetchAllRegions,
  fetchAllZonesByRegionId,
} from "@/shared/graphql/services/layers";
import { graphqlErrorHandler } from "@/shared/utils";
import { isCustomerService } from "@/shared/utils/mappers";
import { addWorkDays, isDateInPast } from "@/shared/utils/dates";
// import schema1 from "@/schemas/1-request.json";
// import uiSchema1 from "@/schemas/ui-schemas/1-request.json";
import { setChoices } from "@/shared/store/slices/installmentSlice";
import {
  insertCitiesInFormSchema,
  insertZonesInFormSchema,
} from "../TaskFormUtils";

export const TaskFormBeneficiaryData = ({ formKey }) => {
  const [formData, setFormData] = useState<any>({
    contact: {
      expectedCall: {},
    },
    request: {},
    location: {},
    familyData: {},
    personalData: {},
    financialData: {},
    supportPackages: {},
    professionalData: {},
    basicTransactionData: {},
    personalRealEstateData: {},
    enthusiasmLevel: {},
    realEstatePreferences: {},
    locationPreferences: {},
    financialPreferences: {},
    negotiatorContactTime: {},
  });
  const { taskId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [formSchema, setFormSchema] = useState({});
  const [regions, setRegions] = useState({});
  const [cities, setCities] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const canSubmit = userInfo?.isSuperuser || isCustomerService(userInfo);
  const willReject =
    formData?.contact?.willingnessToContinueProgram ===
    "لا يرغب في استكمال البرنامج";

  const { loading: taskLoading, data: taskRes } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const clonedBeneficiaryData = JSON.parse(
        JSON.stringify(
          res?.tasks?.data?.at(0)?.formData?.customer_service?.drafts
            ?.beneficiaryData ||
            res?.tasks?.data?.at(0)?.formData?.customer_service?.beneficiaryData
        )
      );

      setFormData((prev) => ({
        ...prev,
        ...clonedBeneficiaryData,
      }));
    },
  });

  const { data, loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    skip: !taskRes,
    onCompleted: async (res) => {
      let clonedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.customer_service?.drafts
            ?.beneficiaryData ||
            taskRes?.tasks?.data?.at(0)?.formData?.customer_service
              ?.beneficiaryData
        )
      );

      const requestedFromSchema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema?.form)
      );
      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );

      // set region id
      if (!clonedFormData.locationPreferences) {
        clonedFormData.locationPreferences = {};
      }
      clonedFormData.locationPreferences.regionId =
        regionsMap[clonedFormData?.locationPreferences?.preferredRegion];

      setRegions(regionsMap);

      if (requestedFromSchema?.definitions?.location?.properties?.region) {
        requestedFromSchema.definitions.location.properties.region.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }

      if (
        requestedFromSchema?.definitions?.locationPreferences?.properties
          ?.preferredRegion
      ) {
        requestedFromSchema.definitions.locationPreferences.properties.preferredRegion.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }

      // checking if the region default value exists
      const prefilledRegion =
        clonedFormData?.locationPreferences?.preferredRegion ||
        clonedFormData?.preferredRegion;
      if (regionsMap[prefilledRegion]) {
        // fetchCities by region id
        const citiesRes = await fetchAllCitiesByRegionId(
          regionsMap[prefilledRegion]?.toString()
        );

        const allCities = citiesRes?.data?.cities?.data;
        const citiesMap = allCities.reduce((hashmap, item) => {
          hashmap[item?.sourceProperties.city_name] =
            item?.sourceProperties?.id;
          return hashmap;
        }, {});
        // set city id
        clonedFormData.locationPreferences.cityId =
          citiesMap[clonedFormData.locationPreferences.preferredCity];
        setCities(citiesMap);
        if (
          requestedFromSchema?.definitions?.locationPreferences?.properties
            ?.preferredCity
        ) {
          requestedFromSchema.definitions.locationPreferences.properties.preferredCity.enum =
            allCities?.map((city) => city.sourceProperties.city_name);
        }
      } else {
        // set formData region null
        // set formData city null
        delete clonedFormData.locationPreferences.preferredRegion;
        delete clonedFormData.locationPreferences.preferredCity;
      }

      // inserting installments options
      if (clonedFormData?.financialData?.installmentChoice === undefined) {
        clonedFormData.financialData.installmentChoice = null;
      }
      updateInstallmentChoices(clonedFormData.financialData);
      // requestedFromSchema = insertInstallementsInFormSchema(
      //   requestedFromSchema,
      //   clonedFormData?.financialData
      // );

      // add 9 days to expected call date
      clonedFormData = addDaysToExpectedCallDate(clonedFormData);

      setFormData((prev) => ({ ...prev, ...clonedFormData }));
      setFormSchema(requestedFromSchema);
    },
  });

  const [updateTask, { loading: updateTaskLoading }] =
    useMutation(UPDATE_CS_TASK);
  const [rejectTaskMutate, { loading: isRejectLoading }] =
    useMutation(REJECT_TASK);

  const [assignToProjectManager, { loading: assignLoading }] = useMutation(
    ASSIGN_TO_PROJECT_MANAGER
  );

  const addDaysToExpectedCallDate = (formData) => {
    const clonedFormData = structuredClone(formData);
    const expectedCallDate =
      clonedFormData?.contact?.expectedCall?.expectedCallDate;
    const callStatus = clonedFormData?.contact?.callStatus;
    const willingnessToContinueProgram =
      clonedFormData?.contact?.willingnessToContinueProgram;
    if (expectedCallDate ? !isDateInPast(new Date(expectedCallDate)) : false)
      return clonedFormData;
    if (
      callStatus === "لم يتم الرد" ||
      willingnessToContinueProgram === "إعادة جدولة الإتصال"
    ) {
      clonedFormData.contact.expectedCall.expectedCallDate = addWorkDays(
        new Date()
      )?.toLocaleDateString("en-CA");
    }
    return clonedFormData;
  };

  const updateInstallmentChoices = (financialData) => {
    dispatch(setChoices(financialData));
  };

  const onFormChange = async (form, fieldId) => {
    let clonedFormData = JSON.parse(JSON.stringify(form?.formData));
    let clonedFormSchema = JSON.parse(JSON.stringify(formSchema));
    if (fieldId === "root_location_region") {
      if (clonedFormData?.location?.city) {
        delete clonedFormData.location?.city;
      }
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[form?.formData?.location?.region]?.toString()
      );

      const allCities = citiesRes?.data?.cities?.data;
      if (clonedFormSchema?.definitions?.location?.properties?.city) {
        clonedFormSchema.definitions.location.properties.city.enum =
          allCities?.map((city) => city.sourceProperties.city_name);
      }
    }

    if (fieldId === "root_locationPreferences_preferredRegion") {
      if (clonedFormData?.locationPreferences?.preferredCity) {
        delete clonedFormData.locationPreferences.preferredCity;
        delete clonedFormData.locationPreferences.cityId;
      }
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
        delete clonedFormData.locationPreferences.mainDivisionId;
      }
      // set region id
      clonedFormData.locationPreferences.regionId =
        regions[form?.formData?.locationPreferences?.preferredRegion];
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[
          form?.formData?.locationPreferences?.preferredRegion
        ]?.toString()
      );
      const allCities = citiesRes?.data?.cities?.data;
      const citiesMap = allCities.reduce((hashmap, item) => {
        hashmap[item?.sourceProperties.city_name] = item?.sourceProperties?.id;
        return hashmap;
      }, {});
      setCities(citiesMap);
      clonedFormSchema = insertCitiesInFormSchema(clonedFormSchema, allCities);
    }
    if (fieldId === "root_locationPreferences_preferredCity") {
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
        delete clonedFormData.locationPreferences.mainDivisionId;
      }
      // set city id
      clonedFormData.locationPreferences.cityId =
        cities[form?.formData?.locationPreferences?.preferredCity];
      const zonesRes = await fetchAllZonesByRegionId(
        cities[form?.formData?.locationPreferences?.preferredCity]?.toString()
      );
      const allZones = zonesRes?.data?.zones?.data?.map((zone) => ({
        id: zone.sourceProperties.id,
        label: zone.sourceProperties?.zone_name,
      }));

      clonedFormSchema = insertZonesInFormSchema(clonedFormSchema, allZones);
    }
    if (
      fieldId === "root_contact_callStatus" ||
      fieldId === "root_contact_willingnessToContinueProgram"
    ) {
      clonedFormData = addDaysToExpectedCallDate(clonedFormData);
    }
    if (
      [
        "root_financialData_salary",
        "root_financialData_monthlyDeduction",
        "root_financialData_remainingDurationMonths",
      ].includes(fieldId)
    ) {
      clonedFormData.financialData.installmentChoice = null;
      updateInstallmentChoices(clonedFormData.financialData);
    }
    setFormData((prev) => ({ ...prev, ...clonedFormData }));
    setFormSchema(clonedFormSchema);
  };
  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const saveAsDraft = () => {
    const submittedData = { beneficiaryData: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        navigate("../tasks");
        toast.success(t("data has been drafted"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const saveOnly =
    formData?.contact?.willingnessToContinueProgram === "إعادة جدولة الإتصال" ||
    formData?.contact?.callStatus === "لم يتم الرد";

  const onSubmit = (form) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));
    const mainDivision = clonedFormData.locationPreferences.mainDivision;

    const isString = (value) =>
      typeof value === "string" || value instanceof String;

    clonedFormData.locationPreferences.mainDivision = mainDivision?.map(
      (el) => {
        if (isString(el)) {
          return {
            id: null,
            label: el,
          };
        }
        return el;
      }
    );

    const isRejected =
      clonedFormData?.contact?.willingnessToContinueProgram ===
      "لا يرغب في استكمال البرنامج";

    const submittedData = { beneficiaryData: clonedFormData };

    if (isRejected) {
      rejectTaskMutate({
        variables: {
          taskInput: {
            taskId,
            formSchemaKey: formKey,
            formData: JSON.stringify(submittedData),
          },
        },
      })
        .then(() => {
          navigate("../tasks");
          toast.success(t("Task Rejected Successfully"));
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
      return;
    }
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        if (!saveOnly) {
          assignToProjectManager({
            variables: { taskInput: { taskId } },
          })
            .then(() => {
              navigate("../tasks");
              toast.success(t("Data has been saved successfully"));
            })
            .catch((err) => {
              graphqlErrorHandler(err);
            });
        } else {
          navigate("../tasks");
          toast.success(t("Data has been saved successfully"));
        }
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const schema = data?.schemas?.data?.at(0)?.jsonSchema;
  const uiSchema = schema?.UISchema;
  if (schemaLoading || taskLoading || !formSchema) return <Loading />;
  const mutationLoading = updateTaskLoading || isRejectLoading || assignLoading;

  return (
    <Form
      formContext={{
        onFileChange,
      }}
      schema={formSchema || {}}
      uiSchema={uiSchema}
      validator={validator}
      showErrorList={false}
      onChange={onFormChange}
      formData={formData}
      experimental_defaultFormStateBehavior={{
        allOf: "populateDefaults",
      }}
      noHtml5Validate
      transformErrors={(errors) => {
        const modfiedErrors = errors?.map((err) => {
          if (
            err.name === "required" ||
            err.name === "minItems" ||
            err.name === "type"
          ) {
            return { ...err, message: "حقل مطلوب" };
          }
          if (err.name === "enum") {
            return {
              ...err,
              message: "يرجى الإختيار من القيم الموجودة",
            };
          }
          if (err.name === "if") {
            return {
              ...err,
              message: "",
            };
          }
          return err;
        });
        return modfiedErrors;
      }}
      widgets={{
        FileWidget,
        YesNoWidget,
        TextWidget,
        SelectWidget,
        DateWidget: DatePickerWidget,
      }}
      fields={{
        FundCalculatorWidget,
      }}
      templates={{ ObjectFieldTemplate }}
      onSubmit={onSubmit}
    >
      <Button
        onClick={saveAsDraft}
        variant="contained"
        sx={{ mt: 2, mr: 1 }}
        disabled={!canSubmit || mutationLoading || willReject}
      >
        {t("save and complete later")}
      </Button>
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={!canSubmit || mutationLoading}
      >
        {saveOnly ? t("save") : t("save and send to the project manager")}
      </Button>
    </Form>
  );
};
