import { statusColorMap } from "@/shared/utils/mappers";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const StatusChip = ({ status, ...props }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={t(status.display)}
      sx={{
        fontWeight: "bold",
        background: statusColorMap?.[status.key]?.background,
        color: statusColorMap?.[status.key]?.color,
      }}
      {...props}
    />
  );
};
