import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BENEFICIARIES_FROM_TASKS } from "@/shared/graphql/queries/tasks";
import { useBeneficiariesFilter } from "./useBeneficiariesFilter";
import { PaginationComponent } from "./PaginationComponent";
import { Loading } from "@/components/Loading";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { useSearchParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { BenefeciariesListPendingReview } from "./BeneficiariesList/BenefeciariesListPendingReview";
import { useTranslation } from "react-i18next";
import { AssignToNegotiatorModal } from "../Orders/OrderTasks/AssignToNegotiatorModal";

export const PendingReviewBeneficiaries = () => {
  const { t } = useTranslation();
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [searchParams] = useSearchParams({ page: "1" });
  const [pageLimit, setPageLimit] = useState<number>(10);
  const { filters, orderBy } = useBeneficiariesFilter();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { data, loading, refetch } = useQuery(GET_BENEFICIARIES_FROM_TASKS, {
    variables: {
      role: userInfo?.role?.queryVar,
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      filters,
      orderBy,
    },
  });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {selectedTasks.length > 0 && (
            <Box sx={{ display: "flex", px: 2, alignItems: "center" }}>
              <Typography color="text.secondary" sx={{ mr: 2.4 }}>
                {t("selected")} {selectedTasks?.length}
              </Typography>
              <Box>
                <AssignToNegotiatorModal
                  buttonVariant="contained"
                  taskIds={selectedTasks}
                  setSelectedTasks={setSelectedTasks}
                  refetch={refetch}
                />
              </Box>
            </Box>
          )}
          <BenefeciariesListPendingReview
            selectedTasks={selectedTasks}
            setSelectedTasks={setSelectedTasks}
            tasks={data?.tasks?.data}
          />
          <Divider />
          <PaginationComponent
            count={data?.tasks?.count}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </>
      )}
    </>
  );
};
