export function calculateFunding(
  salary: number,
  monthlyDeducation: number = 0,
  remainingDuration: number = 0,
  fundingPeriod: number,
  annualPercentage
) {
  const incomeDeductionPercentage = 0.55;
  const productDeductionPercentage = 0.65;

  /* ******************* */
  const deductionPercentageOfPersonalFunding = monthlyDeducation / salary;
  const deductionPercentageAfterEndingPersonalFunding =
    productDeductionPercentage;

  const deductionPercentageDuringRemainingPersonalFunding =
    incomeDeductionPercentage - deductionPercentageOfPersonalFunding;

  const installementAfterEndingPersonalFunding =
    productDeductionPercentage * salary;

  const unknown = deductionPercentageDuringRemainingPersonalFunding * salary;

  const installementDuringRemainingPersonalFunding = Math.min(
    deductionPercentageAfterEndingPersonalFunding * salary,
    unknown
  );

  const totalInstallementDuringPersonalFunding =
    remainingDuration * installementDuringRemainingPersonalFunding;

  const totalInstallementDuringPersonalFundingAfterPersonalFunding =
    installementAfterEndingPersonalFunding *
    (fundingPeriod * 12 - remainingDuration);

  //   ({
  //     "نسبة الاستقطاع من دخل العميل": incomeDeductionPercentage,
  //     "نسبة الاستقطاع للمنتج": productDeductionPercentage,
  //     "نسبة الاستقطاع لتمويل الشخصي": deductionPercentageOfPersonalFunding,
  //     "نسبة الاستقطاع بعد انتهاء التمويل الشخصي":
  //       deductionPercentageAfterEndingPersonalFunding,
  //     "نسبة الاستقطاع خلال فترة التمويل الشخصي المتبقيه":
  //       deductionPercentageDuringRemainingPersonalFunding,
  //     "اجمالي الاقساط خلال فترة التمويل الشخصي":
  //       totalInstallementDuringPersonalFunding,
  //     "القسط خلال فترة التمويل  الشخصي المتبقيه":
  //       installementDuringRemainingPersonalFunding,
  //     "القسط بعد انتهاء التمويل الشخصي": installementAfterEndingPersonalFunding,
  //     "اجمالي الاقساط خلال فترة التمويل المتبقيه  بعد التمويل الشخصي":
  //       totalInstallementDuringPersonalFundingAfterPersonalFunding,
  //     unknown,
  //   });
  /* ******************* */

  const originalTotalAmountWithProfits =
    totalInstallementDuringPersonalFundingAfterPersonalFunding +
    totalInstallementDuringPersonalFunding;

  const totalAmountWithProfits = Math.round(originalTotalAmountWithProfits);
  const originalFundingAmount =
    originalTotalAmountWithProfits /
    (1 + fundingPeriod * (annualPercentage / 100));

  const fundingAmount = Math.round(originalFundingAmount);

  const monthlyInstallmentOfFundingDurationDuringPersonalInstallement =
    Math.round(unknown);

  const monthlyInstallmentOfFundingDurationAfterPersonalInstallement =
    Math.round(installementAfterEndingPersonalFunding);

  const profitMarginOfFundingDuration = Math.round(
    originalTotalAmountWithProfits - originalFundingAmount
  );

  return {
    fundingAmount,
    monthlyInstallmentOfFundingDurationDuringPersonalInstallement,
    monthlyInstallmentOfFundingDurationAfterPersonalInstallement,
    profitMarginOfFundingDuration,
    totalAmountWithProfits,
  };
}
