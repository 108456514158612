import { gql } from "@apollo/client";

export const GET_ALL_SCHEMAS = gql`
  {
    schemas {
      data {
        key
        jsonSchema
      }
    }
  }
`;

export const GET_SCHEMA_BY_KEY = gql`
  query GetSchema($key: String!) {
    schemas(filters: [{ field: "key", value: $key }]) {
      data {
        id
        created
        modified
        jsonSchema
      }
    }
  }
`;
