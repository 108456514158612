import { Box, Button, Modal, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { DELETE_TASK } from "@/shared/graphql/mutations";
import { graphqlErrorHandler } from "@/shared/utils";

export const DeleteTaskModal: FC<{
  taskId: number;
  refetch: () => void;
}> = ({ taskId, refetch }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [deleteTask, { loading: mutationLoading }] = useMutation(DELETE_TASK);

  const onDelete = () => {
    deleteTask({
      variables: {
        taskId,
      },
    })
      .then(() => {
        toast.success(t("Task Deleted Successfully"));
        refetch();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  return (
    <div>
      <Button
        size="small"
        color="error"
        sx={{ fontSize: "small", px: 3, fontWeight: "bold" }}
        onClick={handleOpen}
      >
        {t("Delete Task")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign="center" variant="h6">
            {t("Are you sure you want to delete this task")}
          </Typography>
          <Box display="flex" sx={{ mt: 3 }}>
            <Button
              color="error"
              onClick={onDelete}
              type="submit"
              variant="contained"
              disabled={mutationLoading}
              sx={{ mx: 2 }}
              fullWidth
            >
              {t("Delete")}
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              sx={{ mx: 2 }}
              variant="outlined"
              fullWidth
            >
              {t("Cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
