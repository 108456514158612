import { Typography, Card, Grid } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { t } from "i18next";

export const GeneralInfo = ({ recommendation }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
        {t("District general info")}
      </Typography>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Grid container>
          {/* City Name */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("city_name")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.city_name}
              </Typography>
            </Box>
          </Grid>

          {/* District Name */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("district_name")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.district_name}
              </Typography>
            </Box>
          </Grid>

          {/* District Class */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("district_class")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.district_class}
              </Typography>
            </Box>
          </Grid>

          {/* District ID */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("district_id")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.district_id}
              </Typography>
            </Box>
          </Grid>

          {/* Main Zone */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("main_zone")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.main_zone}
              </Typography>
            </Box>
          </Grid>

          {/* Median Price */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("median_price")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.median_price}
              </Typography>
            </Box>
          </Grid>

          {/* Median Price per m2 */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("median_price_per_m2")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.median_price_per_m2}
              </Typography>
            </Box>
          </Grid>

          {/* Number of Transactions */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_transactions")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.number_of_transactions}
              </Typography>
            </Box>
          </Grid>

          {/* Real Estate Type */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("real_estate_type")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.real_estate_type}
              </Typography>
            </Box>
          </Grid>

          {/* Similarity Percentage */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("similatiy_percentage")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.similatiy_percentage}%
              </Typography>
            </Box>
          </Grid>

          {/* Sub Zone */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("sub_zone")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.sub_zone}
              </Typography>
            </Box>
          </Grid>

          {/* Suitability */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("sutiability")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.sutiability}
              </Typography>
            </Box>
          </Grid>

          {/* Ten Year Growth */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("ten_year_growth")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.ten_year_growth?.toString()?.split(".")?.at(0)}
                .
                {recommendation?.ten_year_growth?.toString()?.split(".")?.at(1)}
                %
              </Typography>
            </Box>
          </Grid>

          {/* Network Analysis - Mosques */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_mosques")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.network_analysis?.mosques?.number_of_mosques}
              </Typography>
            </Box>
          </Grid>

          {/* Network Analysis - Parks */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_parks")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.network_analysis?.parks?.number_of_parks}
              </Typography>
            </Box>
          </Grid>

          {/* Network Analysis - Schools */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_schools")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.network_analysis?.schools?.number_of_schools ||
                  "-"}
              </Typography>
            </Box>
          </Grid>

          {/* Parcels - Built Parcels Percentage */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("built_parcels_percentage")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.parcels?.buidlings?.built_parcels_percentage &&
                  "-"}
                %
              </Typography>
            </Box>
          </Grid>

          {/* Parcels - Number of Built Parcels */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_built_parcels")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.parcels?.buidlings?.number_of_built_parcels}
              </Typography>
            </Box>
          </Grid>

          {/* Parcels - Sum of Parcels Buildings Area */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("sum_of_parcels_buildings_area")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {
                  recommendation?.parcels?.buidlings
                    ?.sum_of_parcels_buildings_area
                }
              </Typography>
            </Box>
          </Grid>

          {/* Parcels - Median of Parcels Area */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("median_of_parcels_area")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.parcels?.general?.median_of_parcels_area}
              </Typography>
            </Box>
          </Grid>

          {/* Parcels - Number of Parcels */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_parcels")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.parcels?.general?.number_of_parcels}
              </Typography>
            </Box>
          </Grid>

          {/* Roads - Median Road Length */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("median_road_length")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.roads?.median_road_length}
              </Typography>
            </Box>
          </Grid>

          {/* Roads - Median Road Width */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("median_road_width")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.roads?.median_road_width}
              </Typography>
            </Box>
          </Grid>

          {/* Roads - Number of Crossroads */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("number_of_crossroads")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.roads?.number_of_crossroads}
              </Typography>
            </Box>
          </Grid>

          {/* Vegetation - Total Vegetation Area */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("total_vegetation_area")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.vegetation?.total_vegetation_area}
              </Typography>
            </Box>
          </Grid>

          {/* Vegetation - Trees Count */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={2}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("trees_count")}
              </Typography>
              <Typography flex={4} fontSize="medium">
                {recommendation?.vegetation?.trees_count}
              </Typography>
            </Box>
          </Grid>

          {/* nearest_commercial_roads */}
          <Grid item xs={12} md={12}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={1}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("nearest_commercial_roads")}
              </Typography>
              <Typography flex={5} fontSize="medium">
                {recommendation?.nearest_commercial_roads?.map((el) =>
                  el ? `${el}, ` : ""
                )}
              </Typography>
            </Box>
          </Grid>

          {/* nearest_major_roads */}
          <Grid item xs={12} md={12}>
            <Box
              display="flex"
              sx={{ borderBottom: "1px solid #E1E1E1", py: 1 }}
            >
              <Typography
                flex={1}
                sx={{ mr: 3, color: "#787878" }}
                fontSize="medium"
              >
                {t("nearest_major_roads")}
              </Typography>
              <Typography flex={5} fontSize="medium">
                {recommendation?.nearest_major_roads?.map((el) => `${el}, `)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
