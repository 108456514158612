export function addWorkDays(startDate: Date, daysToAdd: number = 9): Date {
  const currentDate = new Date(startDate.getTime());
  let daysCounted = 0;
  while (daysCounted < daysToAdd) {
    currentDate.setDate(currentDate.getDate() + 1);
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 5 && dayOfWeek !== 6) {
      daysCounted++;
    }
  }
  return currentDate;
}

export function isDateInPast(date) {
  const today = new Date();

  // Remove the time part from both dates for an accurate comparison
  const todayDateOnly = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const inputDateOnly = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );

  return inputDateOnly < todayDateOnly;
}
