import { Card, Divider, Pagination, Typography } from "@mui/material";
import { TasksList } from "./TasksList";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GET_TASKS } from "@/shared/graphql";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { TasksFilters, useTasksFilter } from "./TasksFilter";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";

export const Tasks = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;
  const { filters } = useTasksFilter();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { data, loading } = useQuery(GET_TASKS, {
    variables: {
      role: userInfo?.role?.queryVar,
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      filters: filters,
      orderBy: searchParams.get("orderBy") || "-modified",
    },
  });

  const numberOfPages = Math.ceil(data?.tasks?.count / pageLimit);
  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("Tasks")}
      </Typography>
      <TasksFilters />
      <Card variant="outlined" sx={{ mb: 1.6 }}>
        <Divider />
        {loading ? <Loading /> : <TasksList tasks={data?.tasks?.data} />}
      </Card>
      <Pagination
        count={numberOfPages || 1}
        page={Number(searchParams.get("page") || 1)}
        onChange={handlePagination}
        variant="outlined"
        shape="rounded"
      />
    </>
  );
};
