import { useState } from "react";
import { DELETE_OFFER_FROM_FAVOURITES } from "@/shared/graphql/mutations";
import { useMutation } from "@apollo/client";
import { Box, Button, MenuItem, Modal, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { graphqlErrorHandler } from "@/shared/utils";

export const DeleteFavouriteOffer = ({ favoriteOfferId, refetchFavOffers }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [deleteOffer, { loading: mutationLoading }] = useMutation(
    DELETE_OFFER_FROM_FAVOURITES
  );

  const onDelete = () => {
    deleteOffer({
      variables: {
        favoriteOfferId: favoriteOfferId,
      },
    })
      .then(() => {
        toast.success(t("Offer Deleted Successfully"));
        refetchFavOffers();
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  return (
    <>
      <MenuItem
        onClick={handleOpen}
        sx={{ fontWeight: "bold", color: "error.main" }}
      >
        {t("Delete from favourite")}
      </MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign="center" variant="h6">
            {t("Are you sure you want to delete this offer from favourites")}
          </Typography>
          <Box display="flex" sx={{ mt: 3 }}>
            <Button
              color="error"
              onClick={onDelete}
              type="submit"
              variant="contained"
              disabled={mutationLoading}
              sx={{ mx: 2 }}
              fullWidth
            >
              {t("Delete")}
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              sx={{ mx: 2 }}
              variant="outlined"
              fullWidth
            >
              {t("Cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
