import { Box, Typography } from "@mui/material";

export const TaskRejectionWarning = ({ task }) => {
  const isNegotiatorRejection =
    !!task?.formData?.negotiator?.negotiatorData?.contact?.reason;

  const contactData = isNegotiatorRejection
    ? task?.formData?.negotiator?.negotiatorData?.contact
    : task?.formData?.customer_service?.beneficiaryData?.contact;

  const reason =
    contactData?.reason === "أخرى" ? contactData?.comment : contactData?.reason;

  return (
    <>
      <Typography
        color="error"
        fontWeight="bold"
        textAlign="center"
        sx={{ mb: 1 }}
      >
        هذه المهمة مرفوضة من المستفيد
      </Typography>

      <Box display="flex" justifyContent="center">
        <Typography fontWeight="bold" sx={{ mb: 2 }} color="text.secondary">
          سبب الرفض: &nbsp;
        </Typography>
        <Typography textAlign="center" fontWeight="bold">
          {reason}
        </Typography>
      </Box>
    </>
  );
};
