import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TaskFormBeneficiaryData } from "./TaskForm";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_SIGNLE_TASK } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { NavLink } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { isProjectManager } from "@/shared/utils/mappers";
import { Notes } from "@/components/Notes/Notes";
import { CallLogs } from "./CallLogs";
import { TaskRejectionWarning } from "./TaskRejectionWarning";

export const Task = () => {
  const { t } = useTranslation();
  const { taskId, orderId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { data: taskRes, loading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
  });
  if (loading) return <Loading />;
  const task = taskRes?.tasks?.data?.at(0);

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <NavLink
          to={isProjectManager(userInfo) ? `../orders/${orderId}` : "../tasks"}
        >
          <EastIcon fontSize="small" sx={{ mr: 1 }} />
        </NavLink>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("task")} {taskId}#
        </Typography>
      </Box>

      {task?.status?.key === 3 ? (
        <TaskRejectionWarning task={task} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box sx={{ position: "sticky", top: 260, overflow: "auto" }}>
                <CallLogs
                  userType="customer service"
                  callLogs={task?.callLogs?.customer_service}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TaskFormBeneficiaryData formKey={"Receiving_beneficiary_data"} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ position: "sticky", top: 260, overflow: "auto" }}>
                <Notes />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
