import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import toast from "react-hot-toast";
import { GET_ALL_DEALS, GET_SCHEMA_BY_KEY } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Box, Button, Typography } from "@mui/material";
import { FINISH_DEAL, UPDATE_DEAL } from "@/shared/graphql/mutations";
import { InfoWidget } from "@/components/RJSF/widgets/InfoWidget/InfoWidget";
// import { RejectDeal } from "./RejectDeal";
import { SignedUrlService } from "@/shared/graphql/services/signedUrl.service";
import { graphqlErrorHandler } from "@/shared/utils";
import EmptyDeals from "@/assets/EmtyDeals.png";

// import schema10 from "@/schemas/10-deal.json";
// import uiSchema10 from "@/schemas/ui-schemas/10-deal.json";

export const Deal = ({ formKey = "deal", canSubmit }) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<any>({
    dealCompletion: {
      financingCompletion: {},
      deedReleaseCheckIssuance: {},
      propertyValuation: {},
      propertyVisit: {},
    },
    negotiationValueDetermination: {},
    dealDetails: {},
    negotiationOwnerValue: {
      negotiationOrder: {},
    },
  });
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const {
    data: dealsRes,
    loading: dealsLoading,
    error: dealError,
  } = useQuery(GET_ALL_DEALS, {
    variables: { taskId },
    onCompleted: (dealsRes) => {
      const deal = dealsRes?.deals?.data?.at(0);
      const taskFormData = deal?.task?.formData?.negotiator;
      const initialFormData = {
        offerData: {
          offerLink: deal?.favoriteOffer?.data?.url,
          licensenumber: deal?.favoriteOffer?.licenseNumber ?? "",
        },
        dealData: {
          avgBuildingArea: "---",
          avgBuildingMeterPrice: "---",
          avgBuildingTotalPrice: "-",
          lastDealDonePrice: "-",
        },
        dealDetails: {},
        negotiationValueDetermination: {},
        negotiationOwnerValue: {
          negotiationOrder: {},
        },
        dealCompletion: {
          financingCompletion: {
            funderBank: taskFormData?.negotiatorData?.financialData?.funderBank,
            funderAmount:
              taskFormData?.negotiatorData?.financialData?.funderAmount,
            finalOfferAgreedAndContractSigned: "لا",
            finalOfferReceived: "لا",
            initialOfferSent: "لا",
            isBeneficiaryAcceptedOffer: "لا",
            shareDataWithBroker: "لا",
          },
          propertyValuation: {},
          deedReleaseCheckIssuance: {},
          propertyTransfer: {},
          propertyVisit: {},
        },
      };
      const prefilledFormData =
        deal?.formData?.drafts || deal?.formData || initialFormData;
      setFormData((prev) => ({ ...prev, ...prefilledFormData }));
    },
  });
  const deal = dealsRes?.deals?.data?.at(0);
  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = res?.schemas?.data?.at(0)?.jsonSchema;
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;

      // const fetchedFormSchema = schema10;
      // const fetchedUiSchema = uiSchema10;

      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });

  const [updateDeal, { loading: mutationLoading }] = useMutation(UPDATE_DEAL);

  const [finishDeal, { loading: finishDealLoading }] = useMutation(FINISH_DEAL);

  const onFormChange = async (form: any) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));

    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };
  const UploadSignedUrlFile = new SignedUrlService();
  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any;
    try {
      if (Array.isArray(data)) {
        const promises = data.map(({ file }) =>
          UploadSignedUrlFile.uploadViaSignedUrl(file, taskId as string)
        );
        response = await Promise.all(promises);
      } else {
        response = await UploadSignedUrlFile.uploadViaSignedUrl(
          data.file,
          taskId as string
        );
      }
    } catch (error) {
      console.error({ error });
    }
    return response;
  };

  const saveAsDraft = () => {
    const dealChecks = formData?.dealCompletion?.financingCompletion;
    let submittedStatus: string | null = null;
    if (dealChecks.shareDataWithBroker === "نعم") {
      submittedStatus = "SHARING_DATA_WITH_CLIENT";
    }
    if (dealChecks.initialOfferSent === "نعم") {
      submittedStatus = "OFFER_SENT_TO_BENEFICIARY";
    }
    if (dealChecks.isBeneficiaryAcceptedOffer === "نعم") {
      submittedStatus = "BENEFICIARY_ACCEPT_THE_OFFER";
    }
    if (dealChecks.finalOfferReceived === "نعم") {
      submittedStatus = "OFFER_RECEIVED";
    }
    if (dealChecks.finalOfferAgreedAndContractSigned === "نعم") {
      submittedStatus = "OFFER_AGREEMENT";
    }
    if (dealChecks.shareDataWithBroker === "لا") {
      submittedStatus = "NEGOTIATE_WITH_OWNER";
    }

    updateDeal({
      variables: {
        dealInput: {
          dealId: Number(deal.id),
          formSchemaKey: formKey,
          formData: JSON.stringify(formData),
          isDraft: true,
          taskStatus: submittedStatus,
        },
      },
    })
      .then(() => {
        toast.success(t("data has been drafted"));
        navigate("../tasks");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    updateDeal({
      variables: {
        dealInput: {
          dealId: Number(deal.id),
          formSchemaKey: formKey,
          formData: JSON.stringify(form.formData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        finishDeal({
          variables: {
            dealInput: { dealId: deal.id, formSchemaKey: formKey },
          },
        })
          .then(() => {
            toast.success(t("Deal finished successfully"));
            navigate("../tasks");
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          });
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  if (schemaLoading || dealsLoading) return <Loading />;
  if (dealError) return <></>;
  if (!deal) {
    return (
      <Box
        sx={{
          mt: 10,
          p: 3,
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Box>
          <Box
            component="img"
            src={EmptyDeals}
            sx={{
              width: "100%",
              maxWidth: "200px",
              height: "auto",
              mb: 3,
            }}
          />
          <Typography fontWeight="bold" sx={{ fontSize: "1.3rem" }}>
            {t("There's no Deal created yet")}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight="bold">
          {t("Deal")}
        </Typography>
        {/* <RejectDeal canSubmit={canSubmit} dealId={deal.id} /> */}
      </Box>
      <Form
        formContext={{ onFileChange }}
        schema={formSchema || {}}
        uiSchema={uiSchema || {}}
        validator={validator}
        onChange={onFormChange}
        formData={formData}
        noHtml5Validate
        showErrorList={false}
        transformErrors={(errors) => {
          const modfiedErrors = errors?.map((err) => {
            if (
              err.name === "required" ||
              err.name === "minItems" ||
              err.name === "type"
            ) {
              return { ...err, message: "حقل مطلوب" };
            }
            if (err.name === "enum") {
              return {
                ...err,
                message: "يرجى الإختيار من القيم الموجودة",
              };
            }
            if (err.name === "if") {
              return {
                ...err,
                message: "",
              };
            }
            return err;
          });
          return modfiedErrors;
        }}
        widgets={{
          FileWidget,
          YesNoWidget,
          TextWidget,
          InfoWidget,
          DateWidget: DatePickerWidget,
        }}
        templates={{ ObjectFieldTemplate }}
        onSubmit={onSubmit}
      >
        <Button
          onClick={saveAsDraft}
          variant="contained"
          sx={{ mt: 2, mr: 1 }}
          disabled={!canSubmit || mutationLoading || finishDealLoading}
        >
          {t("save and complete later")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit || mutationLoading || finishDealLoading}
        >
          {t("Finish deal")}
        </Button>
      </Form>
    </>
  );
};
