import { Box, Typography } from "@mui/material";
import NoOffers from "@/assets/offersState.png";
import { useTranslation } from "react-i18next";

export const OffersEmptyState = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Box
          component="img"
          src={NoOffers}
          sx={{
            width: "100%",
            maxWidth: "200px",
            height: "auto",
            mb: 3,
          }}
        />
        <Typography fontWeight="bold" sx={{ fontSize: "1.3rem" }}>
          {t("No Offers To be Displayed")}
        </Typography>
      </Box>
    </Box>
  );
};
