/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
 * Replace ui:sharedSchema with it's real schema.
 *
 * @param sharedUISchema boject
 * @param uiSchema boject
 * @returns object
 */

export const isObject = <T>(value: T): boolean => value?.constructor === Object;
export const objectDeepMerge = (
  target: object,
  ...items: Array<object>
): object => {
  if (!items.length) {
    return target;
  }

  const [source, ...sources] = items;

  if (isObject(target) && isObject(source)) {
    Object.entries(source).forEach(([key, value]) => {
      if (isObject(value)) {
        target = {
          ...target,
          [key]: objectDeepMerge(target[key] || {}, value),
        };
      } else if (isArray(target[key]) && isArray(value)) {
        target = {
          ...target,
          [key]: [...target[key], ...value],
        };
      } else {
        target = {
          ...target,
          [key]: value,
        };
      }
    });
  }

  return objectDeepMerge(target, ...sources);
};

export const replaceSharedUISchema = (sharedUISchema: object, uiSchema: any) =>
  Object.entries(uiSchema).reduce((uiSchema: any, [key, schema]: any) => {
    let modifiedSchema = schema;
    if (isObject(schema)) {
      modifiedSchema = objectDeepMerge(
        sharedUISchema[schema["ui:sharedSchema"]] ?? {},
        replaceSharedUISchema(sharedUISchema, schema) ?? {}
      );

      delete modifiedSchema["ui:sharedSchema"];
    }

    return {
      ...uiSchema,
      [key]: modifiedSchema,
    };
  }, {});

export const getUrlExt = (url: string): string | undefined => {
  if (!url) return undefined;
  const extension = (url?.split(/[#?]/) ?? [])[0]?.split(".")?.pop()?.trim();

  if (extension && typeof extension === "string") {
    return extension.toLocaleLowerCase();
  }

  return extension;
};
