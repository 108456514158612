import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import { useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TASK_NOTES } from "@/shared/graphql/queries/tasks";
import { CREATE_TASK_NOTE } from "@/shared/graphql/mutations";

const getFullName = (note) => {
  return `${note?.createdBy?.firstName} ${note?.createdBy?.lastName}`;
};

export const Notes = () => {
  const { taskId } = useParams();
  const [input, setInput] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 5;

  const [addNote, { loading: loadingAddNote }] = useMutation(CREATE_TASK_NOTE);

  const handleSendNote = async () => {
    if (input.trim()) {
      const notedAdded = await addNote({
        variables: { noteInput: { taskId, note: input } },
      });
      if (notedAdded) {
        refetch({ offset: 0 });
        setPage(1);
      }
      setInput("");
    }
  };

  const { data, loading, refetch, fetchMore } = useQuery(GET_TASK_NOTES, {
    variables: {
      taskId,
      orderBy: "-created",
      limit: pageSize,
      offset: 0,
    },
  });

  const notes = data?.notes?.data ?? [];
  const totalCount = data?.notes?.count ?? 0;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        offset: notes.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          notes: {
            ...fetchMoreResult.notes,
            data: [...prev.notes.data, ...fetchMoreResult.notes.data],
          },
        };
      },
    });
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <Box
      sx={{
        border: "1px solid #DDD",
        borderRadius: "20px",
        boxShadow: "3px 3px 3px #999",
        mb: 5,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 2,
          minHeight: 200,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            p: 1,
            bgcolor: "#FFF",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">الملاحظات</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxHeight: "35vh",
            overflowY: "auto",
            paddingRight: ".5rem",
            scrollbarWidth: "thin",
          }}
        >
          {loading && page === 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : notes.length > 0 ? (
            <>
              {notes.map((note, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: "#f0f0f0",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#f0f0f0",
                      p: 1,
                      borderRadius: 1,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {note.note}
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: "gray",
                        mt: 0.5,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {getFullName(note)}
                      <span>{moment(note?.created).format("LL")}</span>
                    </Typography>
                  </Box>
                </Box>
              ))}
              {notes.length < totalCount && (
                <Typography
                  sx={{
                    // textDecoration: 'underline',
                    color: "primary.main",
                    cursor: "pointer",
                    textAlign: "center",
                    mt: 1,
                    fontWeight: "bold",
                    width: "fit-content",
                    margin: "0 auto",
                  }}
                  onClick={handleLoadMore}
                >
                  {loading ? "Loading..." : "عرض المزيد"}
                </Typography>
              )}
            </>
          ) : (
            <h5>لا يوجد ملاحظات..</h5>
          )}
        </Box>

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          sx={{ mt: "auto", display: "flex", alignItems: "center" }}
        >
          <IconButton onClick={handleSendNote} disabled={loadingAddNote}>
            <SendIcon />
          </IconButton>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            placeholder="أضف ملاحظة..."
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};
