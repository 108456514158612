/* eslint-disable @typescript-eslint/no-explicit-any */
import fileIcon from "@/assets/file.png";
import pdfIcon from "@/assets/pdf.png";
import CancelIcon from "@mui/icons-material/Cancel";
import clsx from "clsx";

import React, { useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import { getUrlExt } from "../../utils/t";
export interface SingleFilePreviewProps {
  // dataURL: string
  // name: string
  // size: number
  // file: File
  // type: string
  url: string;
  onDelete?: () => void;
  readonly?: boolean;
  onClickImage?: (data: { isImage: boolean; url: string }) => void;
  onDragFile?: (url: string) => void;
  onDropFile?: (url: string) => void;
}

const useStyle = makeStyles(() => ({
  root: {
    width: 200,
    height: 200,
    cursor: "pointer",
    // boxShadow: theme.shadows[2],
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    marginLeft: 1,
    "&:hover": {
      "& .actionButton": {
        display: "inline-flex",
      },
    },
  },
  preview: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  button: {
    width: 40,
    height: 40,
    minWidth: "auto",
    borderRadius: "50%",
    margin: 1,
  },
  // deleteButton: {
  //   backgroundColor: theme.palette.error.main,
  //   color: theme.palette.common.white,
  //   "&:hover": {
  //     backgroundColor: theme.palette.error.main,
  //   },
  // },
}));

const SingleFilePreview = ({
  url,
  onDelete,
  readonly,
  onClickImage,
  onDragFile,
  onDropFile,
}: SingleFilePreviewProps) => {
  const classes = useStyle({ classes: { button: "actionButton" } });
  const fileRef = useRef<HTMLAnchorElement>();

  //   const onDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
  //     event.stopPropagation();
  //     event.preventDefault();
  //     const a = fileRef.current;
  //     if (!a) return;
  //     a.download = "tatabaa_" + Date.now().toString();
  //     a.click();
  //   };

  const cover = useMemo((): string => {
    const ext = getUrlExt(url);
    if (ext?.startsWith("data:image")) {
      return url;
    }

    switch (ext) {
      case "jpg":
      case "png":
      case "gif":
      case "jpeg":
      case "svg":
      case "webp":
      case "heic":
      case "heif":
        return url;
      case "pdf":
        return pdfIcon;
      case "doc":
      case "docx":
        return pdfIcon;
      default:
        return fileIcon;
    }
  }, [url]);

  const onClickImageHanlder = () => {
    const isImage = url === cover;
    if (onClickImage && isImage) {
      onClickImage({ isImage, url: cover });
      return;
    }
    if (url?.startsWith("http")) {
      window.open(url, "_blank");
    } else {
      const image = new Image();
      image.src = url;
      window.open("")?.document.write(image.outerHTML);
    }
  };

  const onDeleteHanlder = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  const onDragStartHandler = () => {
    onDragFile?.call(null, url);
  };

  const onDropHandler = () => {
    onDropFile?.call(null, url);
  };

  return (
    <div
      draggable="true"
      onDragStart={onDragStartHandler}
      onDrop={onDropHandler}
      onDragOver={(e) => e.preventDefault()}
      className={classes.root}
      style={{ backgroundImage: `url(${cover})` }}
      onClick={onClickImageHanlder}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <a ref={fileRef} href={url} target="_blank" style={{ display: "none" }} />
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onDownload}
      >
        <img src={downloadIcon} alt="download" />
      </Button> */}

      {onDelete && !readonly && (
        <IconButton className={clsx(classes.button)} onClick={onDeleteHanlder}>
          <CancelIcon color="error" />
        </IconButton>
      )}
    </div>
  );
};

export default SingleFilePreview;
