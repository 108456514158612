/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Style, Icon } from "ol/style";
import { FieldProps } from "@rjsf/utils";
import { useTranslation } from "react-i18next";
import MapMarker from "@/assets/map-marker.png";
import "ol/ol.css";
import { OL_DEFAULT_CENTER, OL_DEFAULT_ZOOM } from "./mapConstants";
import { Box, Typography } from "@mui/material";

interface GeometryFieldProps extends FieldProps {
  uiSchema?: {
    "ui:title"?: string;
  };
}

export const GeometryField: React.FC<GeometryFieldProps> = ({
  formData,
  onChange,
  errorSchema,
  disabled,
  readonly,
  schema,
  uiSchema,
}) => {
  const { t } = useTranslation();
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [vectorLayer, setVectorLayer] =
    useState<VectorLayer<VectorSource> | null>(null);

  const title = uiSchema?.["ui:title"] || schema.title;

  const errors = [
    ...(errorSchema?.coordinates?.__errors || []),
    ...(errorSchema?.__errors || []),
  ];

  useEffect(() => {
    if (!mapRef.current) return;

    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat(OL_DEFAULT_CENTER),
        zoom: OL_DEFAULT_ZOOM,
      }),
    });

    const layer = new VectorLayer({
      source: new VectorSource(),
    });

    initialMap.addLayer(layer);
    setMap(initialMap);
    setVectorLayer(layer);

    return () => {
      initialMap.setTarget(undefined);
    };
  }, []);

  useEffect(() => {
    if (!map || !vectorLayer) return;

    const [latitude, longitude] = formData?.coordinates ?? []; // Reversed order
    if (!isNaN(latitude) && !isNaN(longitude)) {
      const point = new Point(fromLonLat([latitude, longitude]));
      const feature = new Feature(point);
      feature.setStyle(
        new Style({
          image: new Icon({
            src: MapMarker,
            scale: 0.9,
          }),
        })
      );

      vectorLayer.getSource()?.clear();
      vectorLayer.getSource()?.addFeature(feature);
    } else {
      vectorLayer.getSource()?.clear();
      map.getView().setCenter(fromLonLat(OL_DEFAULT_CENTER));
      map.getView().setZoom(OL_DEFAULT_ZOOM);
    }
  }, [formData, map, vectorLayer]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMapClick = (event: any) => {
    if (disabled || readonly) {
      return;
    }
    const clickedCoord = map?.getCoordinateFromPixel(event.pixel);
    if (clickedCoord) {
      const lonLat = toLonLat(clickedCoord);
      onChange({
        type:
          // @ts-ignore
          ((schema?.properties?.type?.enum ?? []) as string[])[0] ?? "point",
        coordinates: [lonLat[0], lonLat[1]], // Reversed order: [latitude, longitude]
      });

      // Update the marker position without changing the view
      if (vectorLayer) {
        const point = new Point(fromLonLat([lonLat[0], lonLat[1]]));
        const feature = new Feature(point);
        feature.setStyle(
          new Style({
            image: new Icon({
              src: MapMarker,
              scale: 0.9,
            }),
          })
        );
        vectorLayer.getSource()?.clear();
        vectorLayer.getSource()?.addFeature(feature);
      }
    }
  };

  useEffect(() => {
    if (!map) return;

    map.on("click", handleMapClick);
    return () => {
      map.un("click", handleMapClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, disabled, readonly, onChange, schema]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ p: 4 }}>
        <div className="space-y-2">
          <div className="flex items-start justify-between">
            <Typography variant={"h5"} sx={{ my: 2, fontWeight: "bold" }}>
              {title}
            </Typography>
            {errors.length > 0 && (
              <div
                className="text-destructive text-sm font-medium"
                role="alert"
              >
                {errors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            )}
          </div>
          <Box
            ref={mapRef}
            sx={{
              width: "80%",
              height: "300px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              overflow: "hidden",
            }}
            aria-label="Map for selecting location"
          />
        </div>
      </Box>
      {Object.keys(errorSchema || {}).length > 0 && (
        <div className="px-4 pb-4 text-destructive text-sm font-small">
          {t("validations.thisFieldIsRequired")}
        </div>
      )}
    </Box>
  );
};
