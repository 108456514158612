import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

const BordersAndLengthsField = ({
  schema,
  uiSchema,
  formData = {},
  onChange,
  registry,
  idSchema,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const borders = Object.keys(schema.properties);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const isFieldFilled = (field) => {
    const data = formData[field] || {};
    return Object.keys(data).some((key) => !!data[key]);
  };

  return (
    <Box>
      <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
        {borders.map((border) => {
          const filled = isFieldFilled(border);
          return (
            <Tab
              key={border}
              label={schema.properties[border].title}
              sx={{
                bgcolor: filled ? "primary.main" : "#FFF",
                color: filled ? "#FFF" : "#000",
                "&.Mui-selected": {
                  bgcolor: filled ? "primary.main" : "#FFF",
                  color: filled ? "#FFF" : "#000",
                },
              }}
            />
          );
        })}
      </Tabs>

      {borders.map((border, index) => {
        const borderIdSchema = idSchema?.[border] || {};

        return (
          <Box
            key={border}
            role="tabpanel"
            hidden={activeTab !== index}
            sx={{ p: 2, border: "1px solid #ddd", mt: 2 }}
          >
            {activeTab === index && (
              <>
                <registry.fields.ObjectField
                  schema={schema.properties[border]}
                  uiSchema={uiSchema[border]}
                  formData={formData[border]}
                  onChange={(value) =>
                    onChange({
                      ...formData,
                      [border]: value,
                    })
                  }
                  registry={registry}
                  idSchema={borderIdSchema}
                />
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default BordersAndLengthsField;
