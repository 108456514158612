/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LargeImageView from "./LargeImageView";
import { useTranslation } from "react-i18next";
import SingleFilePreview from "./SingleFilePreview";

interface FilesPreviewProps {
  files: string[];
  renderInputComponent?: () => any;
  onDelete?: (index: number) => void;
  onChange?: (files: string[]) => void;
  readonly?: boolean;
}

export const FilesPreview = ({
  files,
  renderInputComponent,
  onDelete,
  readonly,
  onChange,
}: FilesPreviewProps) => {
    const { t } = useTranslation()
    const [largeImage, setLargeImage] = useState('')
    const [draggedFileIndex, setDraggedFileIndex] = useState<number>(0)
  
    if (!files || files.length === 0) {
      return null
    }
  
    const onClickImage = ({ isImage, url }: any) => {
      if (isImage) {
        setLargeImage(url)
      }
    }
  
    const onFileDragged = (index: number) => {
      setDraggedFileIndex(index)
    }
  
    const onFileDropped = (droppedFileIndex: number) => {
      const _tmp = files[draggedFileIndex]
      files[draggedFileIndex] = files[droppedFileIndex]
      files[droppedFileIndex] = _tmp
  
      onChange?.call(null, files)
    }
  return (
    <Box
      sx={{
        display: "flex",
        p: 2,
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LargeImageView
        largeImageView={largeImage}
        setLargeImageView={setLargeImage}
      />
      {files.map((url, index: number) => (
        <SingleFilePreview
          onClickImage={() => onClickImage(url)}
          readonly={readonly}
          key={index}
          url={url}
          onDelete={() => onDelete && onDelete(index)}
          onDragFile={() => onFileDragged(index)}
          onDropFile={() => onFileDropped(index)}
        />
      ))}
      {renderInputComponent && (
        <Box
          sx={{
            width: 200,
            height: 200,
            m: 2,
            position: "relative",
            border: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {renderInputComponent()}
          <AddIcon />
          <Typography>{t("upload more documents")}</Typography>
        </Box>
      )}
    </Box>
  );
};

