import { WidgetProps } from "@rjsf/utils";
import { Typography } from "@mui/material";

export const InfoWidget = ({ schema, value }: WidgetProps) => {
  return (
    <>
      <Typography sx={{ mb: 0.4 }} color="text.secondary" fontWeight="bold">
        {schema.title}
      </Typography>
      <Typography variant="body1">
        {value ? (value as any) : ("---" as any)}
      </Typography>
    </>
  );
};
