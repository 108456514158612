import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export const CustomObjectFieldTemplate = (props: any) => {
  const { title, idSchema } = props;
  return (
    <Box
      sx={
        idSchema["$id"] !== "root"
          ? {
              backgroundColor: "#fff",
              borderRadius: "16px",
              padding: "32px 24px",
              border: "1px solid #E1E1E1",
              boxShadow: "0px 2px 4px -2px #DEDEDE9E",
            }
          : {}
      }
    >
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {props?.properties?.map((prop: any) => {
          return (
            <Grid
              key={prop.name}
              item
              xs={12}
              {...prop?.content?.props?.uiSchema?.["custom:gridProps"]}
            >
              {prop.content}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
