import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#f4f4f4",
        borderTop: "1px solid #DFDFDF",
        py: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      <Box display="flex">
        <Box
          component={NavLink}
          to="/privacy-policy"
          sx={{ fontSize: "small", pr: 1 }}
        >
          سياسة الخصوصية - شروط الإستخدام
        </Box>
        <Typography
          sx={{ fontSize: "small", pl: 1, borderLeft: "1px solid #DFDFDF" }}
        >
          جميع الحقوق محفوظة 2024
        </Typography>
      </Box>
    </Box>
  );
};
