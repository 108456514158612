import { t } from "i18next";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Loading } from "@/components/Loading";
import { useQuery } from "@apollo/client";
import { GET_COORDINATES_DATA } from "@/shared/graphql";

export const CoordinatesModal: React.FC<any> = ({
  open,
  setOpen,
  selectedCoordinates,
  setCoordinatesData,
}) => {
  const { data, loading } = useQuery(GET_COORDINATES_DATA, {
    variables: {
      longitude: selectedCoordinates?.[0],
      latitude: selectedCoordinates?.[1],
    },
    skip: !selectedCoordinates?.[0] || !selectedCoordinates?.[1],
  });
  const locationData = data?.offerLocationData?.data?.reverse_geocoding;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          p: 4,
        }}
      >
        <Typography sx={{ mb: 2, fontSize: "C2C2C2C", fontWeight: "bold" }}>
          {t("Data revisition")}
        </Typography>
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <Box>
            <Typography sx={{ mb: 2, fontSize: "C2C2C2C", fontWeight: "bold" }}>
              {t("are you sure you want to confirm these coordinates")}
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("longitude")}: {selectedCoordinates?.[0] ?? "لا يوجد"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("latitude")}: {selectedCoordinates?.[1] ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("region")}:{" "}
                  {locationData?.region?.region_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("zone")}: {locationData?.zone?.zone_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("sub_zone")}:{" "}
                  {locationData?.sub_zone?.sub_zone_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("plan_number")}:{" "}
                  {locationData?.parcel?.plan_number ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("municipality")}:{" "}
                  {locationData?.municipality?.municipality_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("district")}:{" "}
                  {locationData?.district?.district_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("city")}: {locationData?.city?.city_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("amana")}: {locationData?.amana?.amana_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("street")}:{" "}
                  {locationData?.national_address?.street_arabic_name ??
                    "لا يوجد"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("building_number")}:{" "}
                  {locationData?.national_address?.building_number ?? "لا يوجد"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("zip_code")}:{" "}
                  {locationData?.national_address?.zip_code ?? "لا يوجد"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("additional_number")}:{" "}
                  {locationData?.national_address?.additional_number ??
                    "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("parcel_number")}:{" "}
                  {locationData?.parcel?.parcel_number ?? "لا يوجد"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("block_number")}:{" "}
                  {locationData?.parcel?.block_number ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  {t("road_name")}: {locationData?.road?.road_name ?? "لا يوجد"}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  {t("summary")}: {locationData?.summary ?? "لا يوجد"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 6, mt: 2 }}>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => {
              setCoordinatesData(data);
              setOpen(false);
            }}
            sx={{ px: 3 }}
          >
            {t("save")}
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{ px: 3 }}
          >
            {t("close")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
