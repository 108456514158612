import { Outlet } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BeneficiariesFilters } from "./BeneficiariesFilters/BeneficiariesFilters";
import { BeneficiaryTabs } from "./BeneficiaryTabs";

export const Beneficiaries = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography mb={3} fontWeight="bold" variant="h5">
        {t("Beneficiaries")}
      </Typography>
      <BeneficiariesFilters />
      <Card variant="outlined" sx={{}}>
        <BeneficiaryTabs />
        <Outlet />
      </Card>
    </>
  );
};
