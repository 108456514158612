import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BENEFICIARIES } from "@/shared/graphql";
import { useBeneficiariesFilter } from "./useBeneficiariesFilter";
import { BeneficiariesList } from "./BeneficiariesList";
import { CreateOrderModal } from "./CreateOrderModal";
import { PaginationComponent } from "./PaginationComponent";
import { useSearchParams } from "react-router-dom";
import { Loading } from "@/components/Loading";
import { Box, Divider, Typography } from "@mui/material";
import { t } from "i18next";

export const UnassignedBeneficiaries = () => {
  const [searchParams] = useSearchParams();
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const { filters, orderBy } = useBeneficiariesFilter();

  const { data, loading, refetch } = useQuery(GET_BENEFICIARIES, {
    variables: {
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      filters,
      orderBy,
    },
  });
  if (loading) return <Loading />;
  return (
    <>
      {selectedBeneficiaries.length > 0 && (
        <Box sx={{ display: "flex", px: 2, alignItems: "center" }}>
          <Typography color="text.secondary" sx={{ mr: 2.4 }}>
            {t("selected")} {selectedBeneficiaries?.length}
          </Typography>
          <CreateOrderModal
            setSelectedBeneficiaries={setSelectedBeneficiaries}
            selectedBeneficiaries={selectedBeneficiaries}
            refetchBeneficiaries={refetch}
          />
        </Box>
      )}
      <BeneficiariesList
        beneficiaries={data?.beneficiaries?.data}
        selectedBeneficiaries={selectedBeneficiaries}
        setSelectedBeneficiaries={setSelectedBeneficiaries}
        loading={loading}
      />
      <Divider />
      <PaginationComponent
        count={data?.beneficiaries?.count}
        pageLimit={pageLimit}
        setPageLimit={setPageLimit}
      />
    </>
  );
};
