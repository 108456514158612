/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFunction } from "./isFunction";

export const checkCallback = (callback: any, args: Array<any> = []): any => {
  if (isFunction(callback)) {
    return callback.apply(null, args);
  }

  return callback;
};
