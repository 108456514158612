import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  {
    userDetails {
      id
      isSuperuser
      email
      phone
      firstName
      lastName
      isStaff
      avatar
      roles {
        role
      }
    }
  }
`;
