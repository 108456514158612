import { EmptyData } from "@/components/EmptyData";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const Transactions = ({ transactions }) => {
  const { t } = useTranslation();
  if (transactions?.length === 0) return <EmptyData></EmptyData>;
  return (
    <div>
      <Box sx={{ mb: 3 }}>
        <Card variant="outlined">
          <TableContainer>
            <Table sx={{ minWidth: 2000 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("_amount")}</TableCell>
                  <TableCell>{t("_area")}</TableCell>
                  <TableCell>{t("_city")}</TableCell>
                  <TableCell>{t("_created_on")}</TableCell>
                  <TableCell>{t("_district")}</TableCell>
                  <TableCell>{t("_is_testament")}</TableCell>
                  <TableCell>{t("_land_number")}</TableCell>
                  <TableCell>{t("_plan_number")}</TableCell>
                  <TableCell>{t("_price_per_meter")}</TableCell>
                  <TableCell>{t("_realestate_identity_no")}</TableCell>
                  <TableCell>{t("_realestate_serial")}</TableCell>
                  <TableCell>{t("_request_type")}</TableCell>
                  <TableCell>{t("_request_type_name")}</TableCell>
                  <TableCell>{t("_serial")}</TableCell>
                  <TableCell>{t("_srem_realestate_description")}</TableCell>
                  <TableCell>{t("_transaction_area")}</TableCell>
                  <TableCell>{t("_north_limit_name")}</TableCell>
                  <TableCell>{t("_east_limit_name")}</TableCell>
                  <TableCell>{t("_west_limit_name")}</TableCell>
                  <TableCell>{t("_south_limit_name")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions?.map((transaction) => (
                  <TableRow>
                    <TableCell>{transaction.Amount}</TableCell>
                    <TableCell>{transaction.Area}</TableCell>
                    <TableCell>{transaction.City}</TableCell>
                    <TableCell>{transaction.CreatedOn}</TableCell>
                    <TableCell>{transaction.District}</TableCell>
                    <TableCell>{transaction.IsTestament}</TableCell>
                    <TableCell>{transaction.LandNumber}</TableCell>
                    <TableCell>{transaction.PlanNumber}</TableCell>
                    <TableCell>{transaction.PricePerMeter}</TableCell>
                    <TableCell>{transaction.RealestateIdentityNo}</TableCell>
                    <TableCell>{transaction.RealestateSerial}</TableCell>
                    <TableCell>{transaction.RequestType}</TableCell>
                    <TableCell>{transaction.RequestTypeName}</TableCell>
                    <TableCell>{transaction.Serial}</TableCell>
                    <TableCell>
                      {transaction.SremRealestateDescription}
                    </TableCell>
                    <TableCell>{transaction.TransactionArea}</TableCell>
                    <TableCell>{transaction.NorthLimitName}</TableCell>
                    <TableCell>{transaction.EastLimitName}</TableCell>
                    <TableCell>{transaction.WestLimitName}</TableCell>
                    <TableCell>{transaction.SouthLimitName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </div>
  );
};
