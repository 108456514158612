import { GraphQLErrorExtensions, SourceLocation } from "graphql";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getFullToken } from "@/shared/utils/general";

interface CustomGraphQLErrorExtensions extends GraphQLErrorExtensions {
  http?: {
    status?: number;
    status_text?: string;
    reason?: { [key: string]: string[] | string } | null;
  };
}

type CustomGraphQLError = {
  message?: string;
  locations?: readonly SourceLocation[] | undefined;
  path?: readonly (string | number)[] | undefined;
  extensions?: CustomGraphQLErrorExtensions;
};

export const authLink = new ApolloLink((operation, forward) => {
  const token = getFullToken();
  operation.setContext(({ headers }: { headers: HeadersInit }) => ({
    headers: {
      authorization: token,
      ...headers,
    },
  }));

  return forward(operation);
});

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err: CustomGraphQLError) => {
      console.log(err);
      // toast.error(err.message || err?.extensions?.http?.reason?.message || "");
    });
  }
});

export const httpLink = new HttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
});

export const uploadLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
});
