import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Box, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RangeFilterProps } from "./RangeFilter.interface";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const RangeFilter: React.FC<RangeFilterProps> = ({ field = "age" }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const valueFrom = Number(searchParams.get(`${field}_from`)) || "";
  const valueTo = Number(searchParams.get(`${field}_to`)) || "";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "from") {
      setSearchParams((params) => {
        if (!+event.target.value) {
          params.delete(`${field}_from`);
        } else {
          params.set(`${field}_from`, event.target.value);
        }
        return params;
      });
      // setValue((prev) => [+event.target.value, prev[1]]);
    } else {
      setSearchParams((params) => {
        if (!event.target.value) {
          params.delete(`${field}_to`);
        } else {
          params.set(`${field}_to`, event.target.value);
        }
        return params;
      });
      // setValue((prev) => [prev[0], +event.target.value]);
    }
  };

  return (
    <Box sx={{ mr: 1 }}>
      <Button
        id="menu-button"
        variant="outlined"
        sx={{ borderRadius: "20px" }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {t(field)}
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box display="flex" gap={1}>
            <TextField
              sx={{ width: 200 }}
              size="small"
              type="number"
              id="from"
              name="from"
              label={t("from")}
              variant="outlined"
              onChange={onChange}
              value={valueFrom}
            />
            <TextField
              sx={{ width: 200 }}
              type="number"
              size="small"
              id="to"
              name="to"
              label={t("to")}
              variant="outlined"
              onChange={onChange}
              value={valueTo}
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};
