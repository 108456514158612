import { gql } from "@apollo/client";

export const GET_ALL_RECORDS = gql`
  query GetRecords($layerId: Int!) {
    records(layerId: $layerId) {
      count
      data {
        sourceProperties
      }
    }
  }
`;

export const GET_FILTERED_RECORDS = gql`
  query Cities($layerId: Int!, $value: String!, $field: String!) {
    records(
      layerId: $layerId
      filters: { field: $field, value: $value, clause: iexact }
    ) {
      data {
        sourceProperties
      }
    }
  }
`;

export const GET_ALL_REGIONS = gql`
  query getAllRegions {
    regions {
      data {
        sourceProperties
      }
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query getAllCities($regionId: Int!) {
    cities(regionId: $regionId, pageInfo: {}) {
      data {
        sourceProperties
      }
    }
  }
`;

export const GET_ALL_ZONES = gql`
  query getAllZones($cityId: Int!) {
    zones(cityId: $cityId) {
      data {
        sourceProperties
      }
    }
  }
`;

export const GET_ALL_DISTRICTS = gql`
  query getAllDistricts($cityId: Int!, $zonesId: [Int]) {
    districts(cityId: $cityId, zonesId: $zonesId) {
      data {
        sourceProperties
      }
    }
  }
`;
