import { Coordinate } from "ol/coordinate";

// Define the type for OL coordinates
export type OLCoords = Coordinate;

// Default zoom level
export const OL_DEFAULT_ZOOM = 5;

// Default center coordinates (Saudi Arabia)
export const OL_DEFAULT_CENTER: OLCoords = [45.0792, 23.8859];

// Helper function to convert OLCoords to {lat, lng} format if needed
export const olCoordsToLatLng = (
  coords: OLCoords
): { lat: number; lng: number } => {
  return { lat: coords[1], lng: coords[0] };
};

// Helper function to convert {lat, lng} to OLCoords if needed
export const latLngToOlCoords = (latLng: {
  lat: number;
  lng: number;
}): OLCoords => {
  return [latLng.lng, latLng.lat];
};
