import { AppBar, Box, Container, Typography } from "@mui/material";
import iconHome from "@/assets/icon-home.svg";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export const HomeAppbar = () => {
  const { t } = useTranslation();
  return (
    <AppBar
      color="transparent"
      position="static"
      elevation={0}
      sx={{ py: 2, mb: 4 }}
    >
      <Container>
        <Box component={NavLink} to="/" display="flex" alignItems="center">
          <Box
            component="img"
            width={27}
            src={iconHome}
            sx={{ mr: 1 }}
            alt="icon home"
          />
          <Typography fontWeight="bold">{t("ren")}</Typography>
        </Box>
      </Container>
    </AppBar>
  );
};
