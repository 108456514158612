import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import {
  Box,
  Card,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useQuery } from "@apollo/client";
import { GET_ALL_SCHEMAS } from "@/shared/graphql/queries/schemas";
import { Loading } from "@/components/Loading";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { useTranslation } from "react-i18next";
import { FileWidget, TextWidget, YesNoWidget } from "@/components/RJSF/widgets";

const log = (type: string) => console.log.bind(console, type);

export const FormsPlayground = () => {
  const [selectedForm, setSelectedForm] = useState<any>();
  const [formData, setFormData] = useState({});
  const { data, loading } = useQuery(GET_ALL_SCHEMAS);
  const { t } = useTranslation();

  const onFormChange = ({ formData }: any) => {
    setFormData(formData);
  };
  const onFileChange = async (data: any) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const copyToClipboard = () => {
    const textToCopy = JSON.stringify(formData, undefined, 2);
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy!", err);
      });
  };

  if (loading) return <Loading />;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={3}>
        <FormControl
          fullWidth
          sx={{
            position: "sticky",
            top: 100,
            p: 2,
          }}
        >
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(event) => {
              setSelectedForm(event.target.value as any);
            }}
            value={selectedForm}
          >
            {data?.schemas?.data?.map((form: any) => (
              <MenuItem key={form.key} value={form}>
                {t(form.key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        {selectedForm && (
          <Form
            formContext={{ onFileChange }}
            schema={selectedForm?.jsonSchema?.form || {}}
            uiSchema={selectedForm?.jsonSchema?.UISchema || {}}
            validator={validator}
            showErrorList={"top"}
            onChange={onFormChange}
            widgets={{ FileWidget, YesNoWidget, TextWidget }}
            templates={{ ObjectFieldTemplate }}
            onSubmit={log("submitted")}
            onError={log("errors")}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card
          variant="outlined"
          sx={{
            position: "sticky",
            top: 100,
            p: 2,
            maxHeight: "500px",
            overflow: "auto",
          }}
        >
          <Box>
            <Tooltip title="Copy">
              <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box component={"pre"} sx={{ overflow: "auto" }} dir="ltr">
            {JSON.stringify(formData, undefined, 2)}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
