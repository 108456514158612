/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "@/shared/hooks/useDebounce";

interface SearchFilterInputProps {
    placeholder: string;
    searchParamKey: string;
    setSearchParams: (params) => void;
    defaultValue?: string;
}

export const SearchFilterInput = ({
    placeholder,
    searchParamKey,
    setSearchParams,
    defaultValue = "",
}: SearchFilterInputProps) => {
    const [searchValue, setSearchValue] = useState(defaultValue);
    const debouncedSearchValue = useDebounce(searchValue, 1000);

    useEffect(() => {
        setSearchParams((params) => {
            params.delete("page");
            if (debouncedSearchValue) {
                params.set(searchParamKey, debouncedSearchValue);
            } else {
                params.delete(searchParamKey);
            }
            return params;
        });
    }, [debouncedSearchValue]);

    return (
        <OutlinedInput
            fullWidth
            sx={{
                backgroundColor: "#fff",
                "&:hover": {
                    outline: "#fff !important",
                },
            }}
            placeholder={placeholder}
            value={searchValue}
            startAdornment={
                <InputAdornment position="end" sx={{ pr: 1 }}>
                    <SearchIcon />
                </InputAdornment>
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e.target.value);
            }}
            size="small"
        />
    );
};
