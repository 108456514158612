import { gql } from "@apollo/client";

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($orderInput: CreateOrderInput!) {
    createOrderMutation(orderInput: $orderInput) {
      order {
        id
      }
    }
  }
`;