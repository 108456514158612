import { FC } from "react";
import {
  Button,
  Card,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ORDERS } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { EmptyData } from "@/components/EmptyData";
import { useSorting } from "@/shared/hooks";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

export const Orders: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;
  const { orderBy, sortingValue } = useSorting();
  const { data, loading } = useQuery(GET_ORDERS, {
    variables: {
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      orderBy: sortingValue || "",
    },
  });

  const numberOfPages = Math.ceil(data?.orders?.count / pageLimit);
  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  if (loading) return <Loading />;
  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
        {t("Orders")}
      </Typography>
      <Card variant="outlined" sx={{ mb: 2 }}>
        {data?.orders?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button onClick={() => orderBy("id")}>
                      {t("Order ID")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes("id") ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => orderBy("total_tasks")}>
                      {t("Number of tasks")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes("total_tasks") ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => orderBy("rejected_tasks")}>
                      {t("Rejected tasks")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes("rejected_tasks") ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => orderBy("pending_review_tasks")}>
                      {t("Pending Review")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes("pending_review_tasks") ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        orderBy("assigned_to_customer_services_task")
                      }
                    >
                      {t("Assigned to customers service")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes(
                        "assigned_to_customer_services_task"
                      ) ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => orderBy("assigned_to_negotiator_task")}
                    >
                      {t("Assigned to negotiator")}
                      {sortingValue?.at(0) === "-" &&
                      sortingValue?.includes("assigned_to_negotiator_task") ? (
                        <SouthIcon fontSize="small" />
                      ) : (
                        <NorthIcon fontSize="small" />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.orders?.data?.map((order: any) => (
                  <TableRow
                    component={NavLink}
                    sx={{ "&:hover": { backgroundColor: "#FAFAFA" } }}
                    to={order.id}
                  >
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.tasksCount}</TableCell>
                    <TableCell>{order.rejectedTasksCount}</TableCell>
                    <TableCell>{order.pendingReviewTasksCount}</TableCell>
                    <TableCell>
                      {order.assignedToCustomerServicesTaskCount}
                    </TableCell>
                    <TableCell>{order.assignedToNegotiatorTaskCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>

      <Pagination
        count={numberOfPages || 1}
        page={Number(searchParams.get("page") || 1)}
        onChange={handlePagination}
        variant="outlined"
        shape="rounded"
      />
    </>
  );
};
