import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  isBlueRoot: {
    "& input": {
      color: "#0088ff",
      fontWeight: "bold",
    },
    "& label": {
      color: "#0088ff !important",
    },
    "& fieldset": {
      border: "1px dashed #0088ff !important",
    },
  },
  input: {
    backgroundColor: "#fff",
  },
  isBlueInput: {
    backgroundColor: "#d9edff",
  },
  isBlueInputAdornment: {
    backgroundColor: "#d9edff",
    "& p": {
      color: "#0088ff",
      fontWeight: "bold",
    },
  },
  isGreenRoot: {
    "& input": {
      color: "#4bbc00",
      fontWeight: "bold",
    },
    "& label": {
      color: "#4bbc00 !important",
    },
    "& fieldset": {
      border: "1px solid #4bbc00 !important",
    },
  },
  isGreenInput: {
    backgroundColor: "#f6fbf4",
  },
  isGreenInputAdornment: {
    backgroundColor: "#f6fbf4",
    "& p": {
      color: "#4bbc00",
      fontWeight: "bold",
    },
  },
  isGreyRoot: {
    "& input": {
      color: "#909090",
      fontWeight: "bold",
    },
    "& label": {
      color: "#909090 !important",
    },
    "& fieldset": {
      border: "1px solid #ddd !important",
    },
  },
  isGreyInput: {
    backgroundColor: "#f7f7f7",
  },
  isGreyInputAdornment: {
    backgroundColor: "#f7f7f7",
    "& p": {
      color: "#909090",
      fontWeight: "bold",
    },
  },

  helperTextContainer: {
    color: "#444",
    marginLeft: 0,
    marginRight: 0,
  },
  helperTextContent: {
    display: "block",
    borderLeft: "2px solid #666",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  helperTextDate: {
    display: "block",
  },
}));
