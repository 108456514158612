import { FC } from "react";
import {
  Box,
  Card,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_SERVICES } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { EmptyData } from "@/components/EmptyData";

export const CustomersService: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 10;

  const { data, loading } = useQuery(GET_CUSTOMER_SERVICES, {
    variables: {
      pageInfo: {
        offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
        limit: pageLimit,
      },
    },
  });

  const count = data?.customerServices?.count;
  const numberOfPages = Math.ceil(count / pageLimit);
  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  if (loading) return <Loading />;
  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
        {t("Customer Services")}
      </Typography>
      <Card variant="outlined" sx={{ mb: 2 }}>
        {data?.customerServices?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t("Name")}</TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Assigned tasks")}</TableCell>
                  <TableCell>{t("In Progress")}</TableCell>
                  <TableCell>{t("Finished")}</TableCell>
                  <TableCell>{t("Rejected")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.customerServices?.data?.map((user: any, index) => (
                  <TableRow
                    component={NavLink}
                    sx={{ "&:hover": { backgroundColor: "#FAFAFA" } }}
                    to={`/app/project-manager/beneficiaries/customer-service?assigned_to=${user.id}`}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.totalTasksCount}</TableCell>
                    <TableCell>{user.inProgressTasksCount}</TableCell>
                    <TableCell>{user.finishedTasksCount}</TableCell>
                    <TableCell>{user.rejectedTasksCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
      <Box display="flex" justifyContent="space-between">
        <Pagination
          count={numberOfPages || 1}
          page={Number(searchParams.get("page") || 1)}
          onChange={handlePagination}
          variant="outlined"
          shape="rounded"
        />
        <Typography variant="body2">
          {t("Number of results")}: {count}
        </Typography>
      </Box>
    </>
  );
};
