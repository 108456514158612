import { graphQlAppClient } from "../graphQlAppClient";
import { CREATE_SIGNED_URL_TASK } from "../mutations/signedURL";

export async function uploadToSignedURL(signedUrl: string, file: File) {
  try {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/octet-stream");
    const requestOptions = {
      method: "PUT",
      headers: _headers,
      body: file,
    };

    const response = await fetch(signedUrl, requestOptions as any);
    if (!response.ok) {
      return {
        error: `Failed to upload: ${response.status} ${response.statusText}`,
      };
    }
    return { error: false };
  } catch (error) {
    console.error({ error });
    return { error };
  }
}

export class SignedUrlService {
  async createSignedURL(fileExtension: string, taskId: string) {
    try {
      const { data, errors } = await graphQlAppClient.mutate({
        mutation: CREATE_SIGNED_URL_TASK as any,
        variables: { taskId, fileExtension },
      });
      const { signedUrl: recordSignedUrl } = data;
      const { blobUrl, signedUrl } = recordSignedUrl ?? {};
      return { blobUrl, signedUrl, errors };
    } catch (error) {
      console.error(
        "Backend Error: while trying to generate signed url: ",
        error
      );
      return { blobUrl: null, signedUrl: null, errors: error };
    }
  }

  async uploadViaSignedUrl(file: File, orderId: string) {
    // request signed url from the backend
    const fileExtension = file.name.split(".").pop() as string;
    const { blobUrl, signedUrl, errors } = await this.createSignedURL(
      fileExtension,
      orderId
    );
    if (errors) {
      console.error("Error obtaining signed url", errors);
      // try to upload via backend server graphql
      // if (fallBack) return fallBack(file, organizationId, layerId)
      throw errors;
    }
    // upload the actual file to the signed url
    const { error } = await uploadToSignedURL(signedUrl, file);
    if (error) {
      console.error("Error uploading using signed url", error);
      // try to upload via backend server graphql
      // if (fallBack) return fallBack(file, organizationId, layerId)
      throw error;
    }
    return blobUrl;
  }
}
