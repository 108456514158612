import { calculateFunding } from "@/modules/app/customer-service/Tasks/Task/TaskForm/calaculateFunding";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  choices: [
    {
      fundingPeriod: 10,
      fundingAmount: 0,
      profitMargin: 0,
      totalAmountWithProfits: 0,
      installmentDuringPersonalLoan: 0,
      installmentAfterPersonalLoan: 0,
      annualPercentage: 3.05,
    },
    {
      fundingPeriod: 15,
      fundingAmount: 0,
      profitMargin: 0,
      totalAmountWithProfits: 0,
      installmentDuringPersonalLoan: 0,
      installmentAfterPersonalLoan: 0,
      annualPercentage: 3.5,
    },
    {
      fundingPeriod: 25,
      fundingAmount: 0,
      profitMargin: 0,
      totalAmountWithProfits: 0,
      installmentDuringPersonalLoan: 0,
      installmentAfterPersonalLoan: 0,
      annualPercentage: 3.65,
    },
  ],
};

export const installmentSlice = createSlice({
  name: "installment",
  initialState,
  reducers: {
    setChoices: (state, { payload }) => {
      const { salary, monthlyDeduction, remainingDurationMonths } = payload;

      state.choices.forEach((choice) => {
        const {
          fundingAmount,
          monthlyInstallmentOfFundingDurationDuringPersonalInstallement,
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement,
          profitMarginOfFundingDuration,
          totalAmountWithProfits,
        } = calculateFunding(
          salary,
          monthlyDeduction ?? 0,
          remainingDurationMonths ?? 0,
          choice?.fundingPeriod,
          choice?.annualPercentage
        );

        choice.installmentAfterPersonalLoan =
          monthlyInstallmentOfFundingDurationAfterPersonalInstallement;
        choice.installmentDuringPersonalLoan =
          monthlyDeduction && remainingDurationMonths
            ? monthlyInstallmentOfFundingDurationDuringPersonalInstallement
            : 0;
        choice.fundingAmount = fundingAmount;
        choice.profitMargin = profitMarginOfFundingDuration;
        choice.totalAmountWithProfits = totalAmountWithProfits;
      });
    },
  },
});

export const { setChoices } = installmentSlice.actions;
export default installmentSlice.reducer;
