import { useEffect, useRef, useState } from "react";
import { EmptyData } from "@/components/EmptyData";
import { GET_ALL_RECOMMENDATIONS } from "@/shared/graphql/queries/recommendations";
import { useQuery } from "@apollo/client";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RegionRecommendationMap } from "./RegionRecommendationMap";
import { Loading } from "@/components/Loading";

export const RegionRecommendation = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const rowRefs = useRef<{ [key: string]: HTMLTableRowElement | null }>({});

  const [selectedRecommendationId, setSelectedRecommendationId] =
    useState(null);
  const { data, loading, error } = useQuery(GET_ALL_RECOMMENDATIONS, {
    variables: { taskId, level: "CITY" },
  });
  const preferences =
    data?.recommendations?.data?.recommend_districts_by_preferences;

  const handleRowClick = (id) => {
    setSelectedRecommendationId(id);
  };

  useEffect(() => {
    if (!selectedRecommendationId) return;
    rowRefs.current[selectedRecommendationId]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [selectedRecommendationId]);

  if (loading) return <Loading />;
  if (error) return <></>;
  if (preferences?.length === 0) return <EmptyData />;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={7}>
        <Card variant="outlined">
          <TableContainer>
            <Table
              sx={{ minWidth: 600, tableLayout: "fixed" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("ID")}</TableCell>
                  <TableCell>{t("City")}</TableCell>
                  <TableCell>{t("District")}</TableCell>
                  <TableCell>{t("District class")}</TableCell>
                  <TableCell>{t("Zone")}</TableCell>
                  <TableCell>{t("Sub zone")}</TableCell>
                  <TableCell>{t("Similatiy")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preferences?.map((recommendation) => (
                  <TableRow
                    onClick={() => handleRowClick(recommendation.id)}
                    ref={(el) => (rowRefs.current[recommendation.id] = el)} // Assign ref for each row
                    sx={{
                      cursor: "pointer",
                      bgcolor:
                        selectedRecommendationId === recommendation.id
                          ? "#f1f1f1"
                          : "none",
                    }}
                  >
                    <TableCell>{recommendation.id}</TableCell>
                    <TableCell>{recommendation.city_name}</TableCell>
                    <TableCell>{recommendation.district_name}</TableCell>
                    <TableCell>{recommendation.district_class}</TableCell>
                    <TableCell>{recommendation.main_zone}</TableCell>
                    <TableCell>{recommendation.sub_zone}</TableCell>
                    <TableCell>{t(recommendation.sutiability)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
      <Grid item xs={12} lg={5}>
        <RegionRecommendationMap
          selectedRecommendationId={selectedRecommendationId}
          setSelectedRecommendationId={setSelectedRecommendationId}
          boundaries={data?.recommendations?.data?.boundaries}
          preferences={preferences}
        />
      </Grid>
    </Grid>
  );
};
