import { Box, CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "300px" }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};
