import { gql } from "@apollo/client";

export const ASSIGN_TASK_TO_NEGOTIATOR = gql`
  mutation assignTaskToNegotiator($taskInput: AssignTaskToNegotiatorInput!) {
    assignTaskToNegotiator(taskInput: $taskInput) {
      tasks {
        id
      }
    }
  }
`;
