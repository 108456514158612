import { AppCollapse } from "@/components/AppCollapse";
import { EmptyData } from "@/components/EmptyData";
import { Loading } from "@/components/Loading";
import { GET_ALL_RECOMMENDATIONS } from "@/shared/graphql/queries/recommendations";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { Transactions } from "./Transactions";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GeneralInfo } from "./GeneralInfo";
import NetworkAnalysis from "./NetworkAnalysis";
import { graphqlErrorHandler } from "@/shared/utils";

export const DistrictRecommendations = () => {
  const { taskId } = useParams();
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(GET_ALL_RECOMMENDATIONS, {
    variables: { taskId, level: "DISTRICT" },
    onError: (err) => {
      graphqlErrorHandler(err);
    },
  });

  // const recommendations = [
  //   {
  //     city_name: "الرياض",
  //     district_class: "سكني",
  //     district_id: 119625156,
  //     district_name: "الخزامى",
  //     latest_transactions: [
  //       {
  //         _amount: 0,
  //         _area: 345,
  //         _city: "الرياض",
  //         _created_on: "2024-06-26T20:37:22",
  //         _district: "الخزامى",
  //         _east_limit_name: "قطعة",
  //         _is_testament: 0,
  //         _land_number: "507 / 1643 / 1",
  //         _north_limit_name: "شارع",
  //         _plan_number: "1611 / ج",
  //         _price_per_meter: 0,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "qjgXYOFDpqH0xg3USEvQ8A%3D%3D",
  //         _request_type: 4,
  //         _request_type_name: "هبة",
  //         _serial: 24241385,
  //         _south_limit_name: "قطعة",
  //         _srem_realestate_description: null,
  //         _transaction_area: 345,
  //         _west_limit_name: "قطعة",
  //       },
  //       {
  //         _amount: 1577323,
  //         _area: 157,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T14:46:55",
  //         _district: "الخزامى",
  //         _east_limit_name: null,
  //         _is_testament: 0,
  //         _land_number: "608 / 32",
  //         _north_limit_name: ".",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10019,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "4hB%2BSKQBW4igAUO%2BH6mxdA%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23575587,
  //         _south_limit_name: null,
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 157,
  //         _west_limit_name: null,
  //       },
  //       {
  //         _amount: 1681949,
  //         _area: 158,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T13:54:32",
  //         _district: "الخزامى",
  //         _east_limit_name: ":",
  //         _is_testament: 0,
  //         _land_number: "1 / 1",
  //         _north_limit_name: ":",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10618,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "SlZg4LesGN%2Fn7At92oS5Wg%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23376239,
  //         _south_limit_name: ":",
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 158,
  //         _west_limit_name: ":",
  //       },
  //       {
  //         _amount: 21664008,
  //         _area: 1330,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T13:50:15",
  //         _district: "الخزامى",
  //         _east_limit_name: "شارع",
  //         _is_testament: 0,
  //         _land_number: 93,
  //         _north_limit_name: "شارع",
  //         _plan_number: "2530 / أ",
  //         _price_per_meter: 16289,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "D1fX5ZUU6CO%2BnDgq%2Fra6wA%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23439174,
  //         _south_limit_name: "قطعة",
  //         _srem_realestate_description: null,
  //         _transaction_area: 1330,
  //         _west_limit_name: "قطعة",
  //       },
  //       {
  //         _amount: 1742812,
  //         _area: 163,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T12:57:09",
  //         _district: "الخزامى",
  //         _east_limit_name: ":",
  //         _is_testament: 0,
  //         _land_number: "3 / 2",
  //         _north_limit_name: ":",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10665,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "e9OdJoDtNijZrBbWL5UQNQ%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23553010,
  //         _south_limit_name: ":",
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 163,
  //         _west_limit_name: ":",
  //       },
  //     ],
  //     main_zone: "شمال",
  //     median_price: 1592308,
  //     median_price_per_m2: 10664,
  //     nearest_commercial_roads: [
  //       "ابو بكر الصديق",
  //       "الأميرة سارة بنت الامام عبد الله",
  //       "الامير سطام بن عبد العزيز",
  //       "شارع  عمر بن الخطاب",
  //     ],
  //     nearest_major_roads: [
  //       "الملك خالد",
  //       "الطريق الدائري الغربي",
  //       "الطريق الدائري الشمالي",
  //       "الامير مشعل بن عبدالعزيز",
  //     ],
  //     network_analysis: {
  //       "_al-_amir__abdulaziz_bin__mohammed_bin__ayyaf__park": {
  //         most_driving_distance: null,
  //         most_driving_time: null,
  //       },
  //       "_al-_rajhi__mosque": {
  //         most_driving_distance: 27,
  //         most_driving_time: 30,
  //       },
  //       "_al-_shumaisi__hospital": {
  //         most_driving_distance: 16,
  //         most_driving_time: 18,
  //       },
  //       _al__faisaliah__tower: {
  //         most_driving_distance: 14,
  //         most_driving_time: 15,
  //       },
  //       _digital__city: {
  //         most_driving_distance: 7,
  //         most_driving_time: 7,
  //       },
  //       _diriyah__gate: {
  //         most_driving_distance: 6,
  //         most_driving_time: 7,
  //       },
  //       "_imam__abdulrahman__al-_faisal__hospital": {
  //         most_driving_distance: 33,
  //         most_driving_time: 36,
  //       },
  //       _imam__muhammad_ibn__saud__islamic__university: {
  //         most_driving_distance: 20,
  //         most_driving_time: 22,
  //       },
  //       _king__abdullah__financial__district: {
  //         most_driving_distance: 12,
  //         most_driving_time: 13,
  //       },
  //       _king__fahd__international__stadium: {
  //         most_driving_distance: 30,
  //         most_driving_time: 33,
  //       },
  //       _king__khalid__international__airport: {
  //         most_driving_distance: 37,
  //         most_driving_time: 40,
  //       },
  //       _king__saud__university: {
  //         most_driving_distance: 5,
  //         most_driving_time: 6,
  //       },
  //       _kingdom__tower: {
  //         most_driving_distance: 11,
  //         most_driving_time: 12,
  //       },
  //       _otayyqah__market: {
  //         most_driving_distance: 21,
  //         most_driving_time: 23,
  //       },
  //       _prince__mohammed_bin__abdulaziz__hospital: {
  //         most_driving_distance: 25,
  //         most_driving_time: 28,
  //       },
  //       _prince__sultan__city_for__humanitarian__services: {
  //         most_driving_distance: null,
  //         most_driving_time: null,
  //       },
  //       mosques: {
  //         number_of_mosques: 7,
  //       },
  //       parks: {
  //         number_of_parks: 2,
  //       },
  //       schools: {
  //         number_of_schools: null,
  //       },
  //     },
  //     number_of_transactions: 97,
  //     parcels: {
  //       buidlings: {
  //         built_parcels_percentage: 1,
  //         number_of_built_parcels: 593,
  //         sum_of_parcels_buildings_area: 415098,
  //       },
  //       general: {
  //         median_of_parcels_area: 812,
  //         number_of_parcels: 747,
  //       },
  //     },
  //     real_estate_type: "Apartment",
  //     roads: {
  //       median_road_length: 175,
  //       median_road_width: 15,
  //       number_of_crossroads: 121,
  //     },
  //     similatiy_percentage: 100,
  //     sub_zone: "غرب الشمال",
  //     sutiability: "no_match",
  //     ten_year_growth: 12,
  //     vegetation: {
  //       total_vegetation_area: 234622,
  //       trees_count: 5186,
  //     },
  //   },
  //   {
  //     city_name: "الرياض",
  //     district_class: "سكني",
  //     district_id: 119625156,
  //     district_name: "الخزامى",
  //     latest_transactions: [
  //       {
  //         _amount: 0,
  //         _area: 345,
  //         _city: "الرياض",
  //         _created_on: "2024-06-26T20:37:22",
  //         _district: "الخزامى",
  //         _east_limit_name: "قطعة",
  //         _is_testament: 0,
  //         _land_number: "507 / 1643 / 1",
  //         _north_limit_name: "شارع",
  //         _plan_number: "1611 / ج",
  //         _price_per_meter: 0,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "qjgXYOFDpqH0xg3USEvQ8A%3D%3D",
  //         _request_type: 4,
  //         _request_type_name: "هبة",
  //         _serial: 24241385,
  //         _south_limit_name: "قطعة",
  //         _srem_realestate_description: null,
  //         _transaction_area: 345,
  //         _west_limit_name: "قطعة",
  //       },
  //       {
  //         _amount: 1577323,
  //         _area: 157,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T14:46:55",
  //         _district: "الخزامى",
  //         _east_limit_name: null,
  //         _is_testament: 0,
  //         _land_number: "608 / 32",
  //         _north_limit_name: ".",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10019,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "4hB%2BSKQBW4igAUO%2BH6mxdA%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23575587,
  //         _south_limit_name: null,
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 157,
  //         _west_limit_name: null,
  //       },
  //       {
  //         _amount: 1681949,
  //         _area: 158,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T13:54:32",
  //         _district: "الخزامى",
  //         _east_limit_name: ":",
  //         _is_testament: 0,
  //         _land_number: "1 / 1",
  //         _north_limit_name: ":",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10618,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "SlZg4LesGN%2Fn7At92oS5Wg%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23376239,
  //         _south_limit_name: ":",
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 158,
  //         _west_limit_name: ":",
  //       },
  //       {
  //         _amount: 21664008,
  //         _area: 1330,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T13:50:15",
  //         _district: "الخزامى",
  //         _east_limit_name: "شارع",
  //         _is_testament: 0,
  //         _land_number: 93,
  //         _north_limit_name: "شارع",
  //         _plan_number: "2530 / أ",
  //         _price_per_meter: 16289,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "D1fX5ZUU6CO%2BnDgq%2Fra6wA%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23439174,
  //         _south_limit_name: "قطعة",
  //         _srem_realestate_description: null,
  //         _transaction_area: 1330,
  //         _west_limit_name: "قطعة",
  //       },
  //       {
  //         _amount: 1742812,
  //         _area: 163,
  //         _city: "الرياض",
  //         _created_on: "2024-05-09T12:57:09",
  //         _district: "الخزامى",
  //         _east_limit_name: ":",
  //         _is_testament: 0,
  //         _land_number: "3 / 2",
  //         _north_limit_name: ":",
  //         _plan_number: "1075 / أ",
  //         _price_per_meter: 10665,
  //         _realestate_identity_no: null,
  //         _realestate_serial: "e9OdJoDtNijZrBbWL5UQNQ%3D%3D",
  //         _request_type: 1,
  //         _request_type_name: "صفقة",
  //         _serial: 23553010,
  //         _south_limit_name: ":",
  //         _srem_realestate_description: "شقة",
  //         _transaction_area: 163,
  //         _west_limit_name: ":",
  //       },
  //     ],
  //     main_zone: "شمال",
  //     median_price: 1592308,
  //     median_price_per_m2: 10664,
  //     nearest_commercial_roads: [
  //       "ابو بكر الصديق",
  //       "الأميرة سارة بنت الامام عبد الله",
  //       "الامير سطام بن عبد العزيز",
  //       "شارع  عمر بن الخطاب",
  //     ],
  //     nearest_major_roads: [
  //       "الملك خالد",
  //       "الطريق الدائري الغربي",
  //       "الطريق الدائري الشمالي",
  //       "الامير مشعل بن عبدالعزيز",
  //     ],
  //     network_analysis: {
  //       "_al-_amir__abdulaziz_bin__mohammed_bin__ayyaf__park": {
  //         most_driving_distance: null,
  //         most_driving_time: null,
  //       },
  //       "_al-_rajhi__mosque": {
  //         most_driving_distance: 27,
  //         most_driving_time: 30,
  //       },
  //       "_al-_shumaisi__hospital": {
  //         most_driving_distance: 16,
  //         most_driving_time: 18,
  //       },
  //       _al__faisaliah__tower: {
  //         most_driving_distance: 14,
  //         most_driving_time: 15,
  //       },
  //       _digital__city: {
  //         most_driving_distance: 7,
  //         most_driving_time: 7,
  //       },
  //       _diriyah__gate: {
  //         most_driving_distance: 6,
  //         most_driving_time: 7,
  //       },
  //       "_imam__abdulrahman__al-_faisal__hospital": {
  //         most_driving_distance: 33,
  //         most_driving_time: 36,
  //       },
  //       _imam__muhammad_ibn__saud__islamic__university: {
  //         most_driving_distance: 20,
  //         most_driving_time: 22,
  //       },
  //       _king__abdullah__financial__district: {
  //         most_driving_distance: 12,
  //         most_driving_time: 13,
  //       },
  //       _king__fahd__international__stadium: {
  //         most_driving_distance: 30,
  //         most_driving_time: 33,
  //       },
  //       _king__khalid__international__airport: {
  //         most_driving_distance: 37,
  //         most_driving_time: 40,
  //       },
  //       _king__saud__university: {
  //         most_driving_distance: 5,
  //         most_driving_time: 6,
  //       },
  //       _kingdom__tower: {
  //         most_driving_distance: 11,
  //         most_driving_time: 12,
  //       },
  //       _otayyqah__market: {
  //         most_driving_distance: 21,
  //         most_driving_time: 23,
  //       },
  //       _prince__mohammed_bin__abdulaziz__hospital: {
  //         most_driving_distance: 25,
  //         most_driving_time: 28,
  //       },
  //       _prince__sultan__city_for__humanitarian__services: {
  //         most_driving_distance: null,
  //         most_driving_time: null,
  //       },
  //       mosques: {
  //         number_of_mosques: 7,
  //       },
  //       parks: {
  //         number_of_parks: 2,
  //       },
  //       schools: {
  //         number_of_schools: null,
  //       },
  //     },
  //     number_of_transactions: 97,
  //     parcels: {
  //       buidlings: {
  //         built_parcels_percentage: 1,
  //         number_of_built_parcels: 593,
  //         sum_of_parcels_buildings_area: 415098,
  //       },
  //       general: {
  //         median_of_parcels_area: 812,
  //         number_of_parcels: 747,
  //       },
  //     },
  //     real_estate_type: "Apartment",
  //     roads: {
  //       median_road_length: 175,
  //       median_road_width: 15,
  //       number_of_crossroads: 121,
  //     },
  //     similatiy_percentage: 100,
  //     sub_zone: "غرب الشمال",
  //     sutiability: "no_match",
  //     ten_year_growth: 12,
  //     vegetation: {
  //       total_vegetation_area: 234622,
  //       trees_count: 5186,
  //     },
  //   },
  // ];

  const recommendations =
    data?.recommendations?.data?.recommend_districts_by_preferences;

  if (loading) return <Loading />;
  if (error) return <></>;
  if (!data) return <EmptyData />;

  return (
    <>
      <Box sx={{ mb: 7 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          {t("Latest transaction")}
        </Typography>
        {recommendations?.map((recommendation) => (
          <AppCollapse
            title={`${recommendation?.city_name ?? "--"}, ${recommendation?.district_name}`}
          >
            <Transactions transactions={recommendation.latest_transactions} />
          </AppCollapse>
        ))}
      </Box>
      <Box sx={{ mb: 7 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          {t("District general info")}
        </Typography>
        {recommendations?.map((recommendation) => (
          <AppCollapse
            title={`${recommendation?.city_name ?? "--"}, ${recommendation?.district_name}`}
          >
            <GeneralInfo recommendation={recommendation} />
          </AppCollapse>
        ))}
      </Box>

      <Box sx={{ mb: 7 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          {t("network_analysis")}
        </Typography>
        {recommendations?.map((recommendation) => (
          <AppCollapse
            title={`${recommendation?.city_name ?? "--"}, ${recommendation?.district_name}`}
          >
            <NetworkAnalysis
              network_analysis={recommendation?.network_analysis}
            />
          </AppCollapse>
        ))}
      </Box>
    </>
  );
};
