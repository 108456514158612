import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { WidgetProps, getDisplayLabel } from "@rjsf/utils";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useStyles } from "./TextWidget.style";

export type TextWidgetProps = WidgetProps & TextFieldProps;

export const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  schema,
  uiSchema,
  rawErrors = [],
  ...textFieldProps
}: any) => {
  const classes = useStyles();
  const [_value, setValue] = useState(value);

  const isObject = (value): boolean => value?.constructor === Object;
  let _inputType =
    (type || schema.type) === "string" ? "text" : `${type || schema.type}`;
  if (schema.format === "date") {
    _inputType = "date";
  }

  const isNumberType = ["integer", "number"].includes(_inputType);

  const inputType = isNumberType ? "text" : _inputType;

  const getFinalValue = (value: any) => {
    if (isNumberType) {
      return String(value).replace(/(?!^-)[^0-9.]/g, "");
    }

    return value;
  };

  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(getFinalValue(value === "" ? "" : value));
  };

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, getFinalValue(value));

  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, getFinalValue(value));

  const hasValue = (data): boolean => {
    if (isObject(data) || Array.isArray(data)) {
      const values = Object.values(data);
      return !!values.length && values.some(hasValue);
    } else if (typeof data === "string") {
      return !!data.replace(/\s+/g, "").length;
    } else if (data && typeof data === "boolean") {
      return [true, false].includes(data);
    } else if ([null, undefined].includes(data)) {
      return false;
    }

    return true;
  };

  const displayLabel = getDisplayLabel(
    schema as any,
    uiSchema as any
    /* TODO: , rootSchema */
  );

  const isGreyStyle = !!uiSchema["ui:greyStyle"];
  const isBlueStyle = !!uiSchema["ui:blueStyle"];
  const isGreenStyle = !!uiSchema["ui:greenStyle"];
  const isCalculated = !!uiSchema["ui:calculation"];

  useEffect(() => {
    if (isNumberType) {
      const valueStr = value === undefined ? "" : String(value);
      const formatter = new Intl.NumberFormat("en-US");

      const startsWithNegative = valueStr.startsWith("-");
      const endsWithDot = valueStr.endsWith(".");

      // Extract integer and fractional parts
      const [integerPart, fractionalPart]: any = valueStr.split(".");

      // Format the integer part only
      const formattedIntegerPart = integerPart
        ? formatter.format(
            startsWithNegative ? integerPart.slice(1) : integerPart
          )
        : "";

      const finalFormattedIntegerPart = startsWithNegative
        ? `-${formattedIntegerPart}`
        : formattedIntegerPart;

      // Reconstruct the final value
      const finalValue =
        fractionalPart !== undefined
          ? `${finalFormattedIntegerPart}.${fractionalPart}`
          : finalFormattedIntegerPart + (endsWithDot ? "." : "");
      setValue(finalValue);
    } else {
      setValue(value);
    }
  }, [value, isNumberType]);

  return (
    <TextField
      id={id}
      className={clsx({
        [classes.isGreyRoot]: isGreyStyle,
        [classes.isBlueRoot]: isBlueStyle,
        [classes.isGreenRoot]: isGreenStyle,
      })}
      InputLabelProps={{
        shrink: inputType === "date" ? true : hasValue(_value),
      }}
      InputProps={{
        inputProps:
          inputType === "date"
            ? {
                // eslint-disable-next-line no-extra-boolean-cast
                min: !uiSchema["custom:noMinDateToday"]
                  ? new Date().toISOString().split("T")[0]
                  : undefined,
              }
            : {},
        className: clsx(classes.input, {
          [classes.isGreyInput]: isGreyStyle,
          [classes.isBlueInput]: isBlueStyle,
          [classes.isGreenInput]: isGreenStyle,
        }),
        endAdornment: uiSchema["ui:suffix"] && (
          <InputAdornment
            position="start"
            className={clsx({
              [classes.isGreyInputAdornment]: isGreyStyle,
              [classes.isBlueInputAdornment]: isBlueStyle,
              [classes.isGreenInputAdornment]: isGreenStyle,
            })}
          >
            {uiSchema["ui:suffix"]}
          </InputAdornment>
        ),
      }}
      variant="outlined"
      placeholder={placeholder}
      label={
        displayLabel ? uiSchema["ui:label"] || label || schema.title : false
      }
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly || uiSchema["ui:disabled"] || isCalculated}
      type={inputType}
      value={_value ?? ""}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      helperText={
        uiSchema["ui:hint"] && (
          <span className={classes.helperTextContent}>
            {uiSchema["ui:hint"]}
          </span>
        )
      }
      FormHelperTextProps={{
        className: classes.helperTextContainer,
      }}
      {...(textFieldProps as TextFieldProps)}
    />
  );
};
