import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import { t } from "i18next";
import { FC, useState } from "react";
import { styles } from "./CreateOrderModal.style";
import validator from "@rjsf/validator-ajv8";
import { Form } from "@rjsf/mui";
import createOrderSchema from "@/schemas/create-order.json";
import uiSchema from "@/schemas/ui-schemas/create-order.json";
import { RJSFSchema } from "@rjsf/utils";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER_MUTATION } from "@/shared/graphql/mutations/orders";
import { CreateOrderModalI } from "./CreateOrderModal.interface";
import { GET_ALL_CUSTOMER_SERVICES } from "@/shared/graphql/queries/customerServices";
import toast from "react-hot-toast";
import { DatePickerWidget } from "@/components/RJSF/widgets";

export const CreateOrderModal: FC<CreateOrderModalI> = ({
  selectedBeneficiaries,
  refetchBeneficiaries,
  setSelectedBeneficiaries,
}) => {
  const [open, setOpen] = useState(false);
  const [schema, setSchema] = useState({});
  const [createOrder, { loading }] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted: () => {
      toast.success(t("order created successfully"));
      setOpen(false);
      setSelectedBeneficiaries([]);
      refetchBeneficiaries();
    },
  });
  useQuery(GET_ALL_CUSTOMER_SERVICES, {
    onCompleted: (res) => {
      const customerServicesEnums = res?.customerServices?.data?.map(
        (el: any) => +el.id
      );
      const customerServicesEnumNames = res?.customerServices?.data?.map(
        (el: any) => `${el.firstName} ${el.lastName}`
      );
      const schema = createOrderSchema;
      schema.properties.customerServiceId.enum = customerServicesEnums;
      schema.properties.customerServiceId.enumNames = customerServicesEnumNames;
      setSchema(schema);
    },
  });

  const onSubmit = (data: any) => {
    createOrder({
      variables: {
        orderInput: {
          beneficiaries: selectedBeneficiaries,
          customerService: data?.formData?.customerServiceId,
          startDate: new Date(data?.formData?.startDate).toISOString(),
          endDate: new Date(data?.formData?.endDate).toISOString(),
        },
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        style={{ margin: ".5rem 0" }}
      >
        {t("Create Order")}
      </Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            sx={{ fontWeight: "bold" }}
          >
            {t("Create Order")}
          </Typography>
          <Divider />

          <Form
            schema={schema as RJSFSchema}
            noHtml5Validate
            showErrorList={false}
            transformErrors={(errors) => {
              const modfiedErrors = errors?.map((err) => {
                if (
                  err.name === "required" ||
                  err.name === "minItems" ||
                  err.name === "type"
                ) {
                  return { ...err, message: "حقل مطلوب" };
                }
                if (err.name === "enum") {
                  return {
                    ...err,
                    message: "يرجى الإختيار من القيم الموجودة",
                  };
                }
                if (err.name === "if") {
                  return {
                    ...err,
                    message: "",
                  };
                }
                return err;
              });
              return modfiedErrors;
            }}
            uiSchema={uiSchema}
            widgets={{ DateWidget: DatePickerWidget }}
            validator={validator}
            onSubmit={onSubmit}
          >
            <Box display="flex" sx={{ mt: 3 }}>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                sx={{ mx: 2 }}
                fullWidth
              >
                {t("Create Order")}
              </Button>
              <Button
                disabled={loading}
                type="submit"
                onClick={handleClose}
                color="primary"
                sx={{ mx: 2 }}
                variant="outlined"
                fullWidth
              >
                {t("Cancel")}
              </Button>
            </Box>
          </Form>
        </Box>
      </Modal>
    </>
  );
};
