import { gql } from "@apollo/client";

export const UPDATE_TASK = gql`
  mutation UpdateTaskMutation($taskInput: UpdateTaskInput!) {
    updateTaskMutation(taskInput: $taskInput) {
      task {
        id
        formData
      }
    }
  }
`;

export const UPDATE_CS_TASK = gql`
  mutation updateCustomerServiceTaskMutation($taskInput: UpdateTaskInput!) {
    updateCustomerServiceTaskMutation(taskInput: $taskInput) {
      task {
        id
        formData
      }
    }
  }
`;

export const UPDATE_NEGOTIATOR_TASK = gql`
  mutation updateNegotiatorTaskMutation($taskInput: UpdateTaskInput!) {
    updateNegotiatorTaskMutation(taskInput: $taskInput) {
      task {
        id
        formData
      }
    }
  }
`;

export const ASSIGN_TO_PROJECT_MANAGER = gql`
  mutation assignTaskToProjectManager($taskInput: AssignTaskInput!) {
    assignTaskToProjectManager(taskInput: $taskInput) {
      task {
        id
      }
    }
  }
`;

export const REJECT_TASK = gql`
  mutation rejectTaskMutation($taskInput: CloseTaskInput!) {
    rejectTaskMutation(taskInput: $taskInput) {
      task {
        id
      }
    }
  }
`;

export const FINISH_TASK = gql`
  mutation FinishTaskMutation($taskInput: AssignTaskInput!) {
    finishTaskMutation(taskInput: $taskInput) {
      task {
        id
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation deleteTaskMutation($taskId: Int!) {
    deleteTaskMutation(taskId: $taskId) {
      status
    }
  }
`;

export const CHANGE_TASK_STATUS = gql`
  mutation changeTaskStatus($taskId: Int!, $status: TaskStatus!) {
    changeTaskStatus(taskInput: { taskId: $taskId, status: $status }) {
      task {
        id
      }
    }
  }
`;

export const REASSIGN_TO_CUSTOMER_SERVICE = gql`
  mutation reassignTaskToCustomerService($taskInput: ReassignTaskInput!) {
    reassignTaskToCustomerService(taskInput: $taskInput) {
      tasks {
        id
      }
    }
  }
`;

export const REASSIGN_TO_NEGOTIATOR = gql`
  mutation reassignTaskToNegotiator($taskInput: ReassignTaskInput!) {
    reassignTaskToNegotiator(taskInput: $taskInput) {
      tasks {
        id
      }
    }
  }
`;

export const CREATE_TASK_NOTE = gql`
  mutation createTaskNote($noteInput: CreateNoteInput!) {
    createNote(noteInput: $noteInput) {
      note {
        note
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
`;
