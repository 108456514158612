import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { HomeAppbar } from "../LandingPage/HomeAppbar";

export const PrivacyPolicy = () => {
  return (
    <div>
      <HomeAppbar />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Typography marginBottom={2}>
              شركة التقنيات المكانية المحدودة، ورقم سجلها التجاري 1010452500 ،
              وعنوانها في حي العليا، طريق الملك فهد، 12331 الرياض ("الطرف
              المُفصِح").
            </Typography>
            <Typography marginBottom={2}>
              يهدف هذا الاتفاق إلى تحديد الأطر والشروط اللازمة لحماية المعلومات
              السرية والحفاظ على سريتها، والتي قد يتم الكشف عنها أو الحصول عليها
              خلال العلاقة بين الطرفين.
            </Typography>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                الغرض من الاتفاقية:
              </Typography>
              <Typography marginBottom={2}>
                هذه الاتفاقية تهدف إلى حماية والحفاظ على سرية وأمان المعلومات
                السرية التي يتم الكشف عنها أو الحصول عليها من قبل الطرف المُتلقي
                خلال مدة العمل أو بعدها.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                المعلومات السرية:
              </Typography>
              <Typography marginBottom={2}>
                تشمل المعلومات السرية، بدون قيود، كل المعلومات المتعلقة
                بالعمليات التجارية والحكومية، الإستراتيجية، المالية، التقنية،
                البحوث، الابتكارات، البراءات، الأسرار التجارية، البيانات الشخصية
                للعملاء، ومعلومات الموظفين والشركاء.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                التزامات الطرف المُتلقي:
              </Typography>
              <Typography marginBottom={2}>
                الحفاظ على سرية المعلومات السرية بمستوى عالٍ من الدقة والأمان،
                وعدم الإفصاح عن المعلومات السرية لأطراف ثالثة دون موافقة مسبقة
                خطية من الطرف المُفصِح، الاحتفاظ بالمعلومات السرية بشكل آمن ومنع
                الوصول غير المصرح به إليها، إعادة أو إتلاف المعلومات السرية إلى
                الطرف المُفصِح عند طلبه أو عند انتهاء العمل أو العلاقة العمالية.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                تدابير الأمان:
              </Typography>
              <Typography marginBottom={2}>
                يجب على الطرف المُتلقي اتخاذ جميع التدابير الأمنية اللازمة
                لحماية المعلومات السرية من السرقة، التلف، الإفشاء غير المصرح به،
                أو الاستخدام غير القانوني. يجب على الطرف المُتلقي إخطار الطرف
                المُفصِح فورًا في حال حدوث أي خرق للأمان يؤثر على المعلومات
                السرية.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                مدة الاتفاقية:
              </Typography>
              <Typography marginBottom={2}>
                تظل هذه الاتفاقية سارية لأجل غير مسمى ولا تنتهي حتى بعد انتهاء
                العلاقة العمالية، مع استمرار التزامات السرية لمدة [ 99 ] سنة بعد
                انتهاء هذه العلاقة.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                عواقب الإخلال:
              </Typography>
              <Typography marginBottom={2}>
                في حالة الإخلال بأحكام هذه الاتفاقية، يُعرض الطرف المُتلقي نفسه
                للمساءلة القانونية، بما في ذلك المطالبة بتعويضات واتخاذ إجراءات
                قضائية.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                القانون الحاكم والاختصاص القضائي:
              </Typography>
              <Typography marginBottom={2}>
                تخضع هذه الاتفاقية وتُفسر وفقًا لقوانين المملكة العربية
                السعودية، ويكون للمحاكم المحلية الاختصاص القضائي الحصري للنظر في
                أي نزاعات قد تنشأ عنها.
              </Typography>
            </Box>
            <Box>
              <Typography marginBottom={2}>
                في حال وجود أي تعارض بين النص العربي والنص الإنجليزي لهذه
                الاتفاقية، يعتمد النص العربي كمرجع، وتُعد التوقيعات أدناه موافقة
                من الطرفين على جميع بنود وشروط هذه الاتفاقية وأحكامها بكل أمانة
                وصدق، ويشهد الله على ما تم الاتفاق عليه والتوقيع تحته.
              </Typography>
            </Box>
            <FormControlLabel
              control={<Checkbox />}
              label="أوافق على الشروط والأحكام"
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ textAlign: "right" }}>
            <Typography marginBottom={2}>
              Geospatial Technologies Limited, with commercial registration
              number 1010452500, and located at Al Olaya, King Fahd Road, 12331
              Riyadh ("the Disclosing Party").
            </Typography>
            <Typography marginBottom={2}>
              The purpose of this agreement is to define the frameworks and
              conditions necessary to protect confidential information and
              maintain its secrecy, which may be disclosed or obtained during
              the relationship between the parties.
            </Typography>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Purpose of the Agreement:
              </Typography>
              <Typography marginBottom={2}>
                This agreement aims to protect and maintain the confidentiality
                and security of the confidential information disclosed or
                obtained by the Receiving Party during and after the term of
                employment.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Confidential Information:
              </Typography>
              <Typography marginBottom={2}>
                Confidential information includes, without limitation, all
                information related to business and governmental operations,
                strategy, financial, technical, research, innovations, patents,
                trade secrets, personal data of clients, and information of
                employees and partners.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Obligations of the Receiving Party:
              </Typography>
              <Typography marginBottom={2}>
                To maintain the confidentiality of the confidential information
                with a high level of accuracy and security, not to disclose the
                confidential information to third parties without prior written
                consent from the Disclosing Party, to securely store the
                confidential information and prevent unauthorized access, and to
                return or destroy the confidential information to the Disclosing
                Party upon request or at the end of the employment or business
                relationship.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Security Measures:
              </Typography>
              <Typography marginBottom={2}>
                The Receiving Party must take all necessary security measures to
                protect the confidential information from theft, damage,
                unauthorized disclosure, or illegal use. The Receiving Party
                must immediately notify the Disclosing Party in case of any
                security breach affecting the confidential information.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Duration of the Agreement:
              </Typography>
              <Typography marginBottom={2}>
                This agreement remains valid indefinitely and does not end even
                after the termination of the employment relationship, with
                continuing confidentiality obligations for [99] years after the
                end of this relationship.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Consequences of Breach:
              </Typography>
              <Typography marginBottom={2}>
                In case of a breach of this agreement's terms, the Receiving
                Party exposes itself to legal accountability, including claims
                for damages and legal proceedings.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight="bold" sx={{ mb: 0.4 }}>
                Governing Law and Jurisdiction:
              </Typography>
              <Typography marginBottom={2}>
                This agreement is subject to and interpreted according to the
                laws of the Kingdom of Saudi Arabia, and local courts have
                exclusive jurisdiction to resolve any disputes arising from it.
              </Typography>
            </Box>
            <Box>
              <Typography marginBottom={2}>
                In case of any conflict between the Arabic and English texts of
                this agreement, the Arabic text will be considered as the
                reference. The signatures below indicate the parties' agreement
                to all terms and conditions of this agreement and its
                provisions, honestly and truthfully, and God is a witness to
                what has been agreed and signed.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};