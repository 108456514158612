import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CallLogsI } from "./CallLogsProps.interface";
import { useTranslation } from "react-i18next";

export const CallLogs = ({
  callLogs,
  title = "Call logs",
  userType = "customer service",
}: CallLogsI) => {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" sx={{ py: 2, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1, ml: 1.4 }}>
        {t(title)}
      </Typography>
      <Divider />
      {callLogs?.length === 0 || !callLogs ? (
        <Typography textAlign="center" fontWeight="bold" mt={2}>
          {t("No data")}
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableCell>{t("Date and time")}</TableCell>
              {userType === "customer service" && (
                <TableCell>{t("Status")}</TableCell>
              )}
              <TableCell>{t("Date and time of expected call")}</TableCell>
            </TableHead>
            <TableBody>
              {callLogs?.map((record) => {
                const date = new Date(record.date_time);

                return (
                  <TableRow>
                    <TableCell>
                      {date.toLocaleDateString("en-UK")} -{" "}
                      {date.toLocaleTimeString("en-SA", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}
                    </TableCell>
                    {userType === "customer service" && (
                      <TableCell>{record.status}</TableCell>
                    )}
                    <TableCell>
                      {record?.expected_call?.expectedCallDate} {" - "}
                      {record?.expected_call?.expectedCallTime}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  );
};
