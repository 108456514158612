export const projectManagerLinks = [
  { to: "/app/project-manager/beneficiaries", text: "Beneficiaries" },
  { to: "/app/project-manager/orders", text: "Orders" },
  { to: "/app/project-manager/customer-service", text: "Customer Service" },
  { to: "/app/project-manager/negotiators", text: "Negotiators" },
];

export const customerServiceLinks = [
  { to: "/app/customer-service/tasks", text: "Tasks" },
];

export const negotiatorLinks = [
  { to: "/app/negotiator/tasks", text: "Tasks" },
];
