import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface PaginationComponentProps {
  count: number;
  pageLimit?: number;
  setPageLimit?: (limit: number) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  count,
  pageLimit = 10,
  setPageLimit,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const numberOfPages = Math.ceil(count / pageLimit);

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" margin="1rem">
      <Pagination
        count={numberOfPages || 1}
        page={Number(searchParams.get("page") || 1)}
        onChange={handlePagination}
        variant="outlined"
        shape="rounded"
      />
      <Box>
        <FormControl sx={{ width: "120px", mb: 0.4 }}>
          <InputLabel id="page-limit-select-label">
            {t("Results per page")}
          </InputLabel>
          <Select
            labelId="page-limit-select-label"
            id="page-limit-select"
            label={t("Results per page")}
            value={pageLimit}
            onChange={(e) => {
              setSearchParams((params) => {
                params.set("page", "1");
                return params;
              });
              if (setPageLimit) {
                setPageLimit(Number(e.target.value) || 0);
              }
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={1000}>1000</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="body2">
          {t("Number of results")}: {count ?? 0}
        </Typography>
      </Box>
    </Box>
  );
};
