import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type UserI = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: {
    key: number;
    display: string;
    queryVar: string;
    links: { to: string; text: string }[];
  };
  roles: any;
  isSuperuser: boolean;
};

export interface AuthState {
  isAuthenticated: boolean;
  userInfo: UserI | null;
  userToken: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  userInfo: null,
  userToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ userInfo: UserI; userToken: string }>
    ) => {
      state.isAuthenticated = true;
      state.userInfo = action.payload.userInfo;
      state.userToken = action.payload.userToken;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userInfo = null;
      state.userToken = null;
    },
  },
});

export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
