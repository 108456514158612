import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18next";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppThemeProvider } from "./shared/AppThemeProvider";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "@/shared/store";
import { AuthProvider } from "@/shared/auth";
import { ApolloProvider } from "@apollo/client";
import { graphQlAppClient } from "@/shared/graphql";
import { initSentryForProduction } from "../sentry";
import { initClarity } from "../clarity";

// initialize sentry
initSentryForProduction();
// initialize clarity
initClarity();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={graphQlAppClient}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <AppThemeProvider>
              <App />
            </AppThemeProvider>
          </AuthProvider>
        </ReduxProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);
