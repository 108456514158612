import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_BENEFICIARIES_FROM_TASKS } from "@/shared/graphql/queries/tasks";
import { useBeneficiariesFilter } from "./useBeneficiariesFilter";
import { BeneficiariesListCS } from "./BeneficiariesList/BeneficiariesListCS";
import { PaginationComponent } from "./PaginationComponent";
import { Loading } from "@/components/Loading";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { useSearchParams } from "react-router-dom";
import { Divider } from "@mui/material";

export const CustomerServiceBeneficiaries = () => {
  const [searchParams] = useSearchParams({ page: "1" });
  const [pageLimit, setPageLimit] = useState<number>(10);
  const { filters, orderBy } = useBeneficiariesFilter();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { data, loading } = useQuery(GET_BENEFICIARIES_FROM_TASKS, {
    variables: {
      role: userInfo?.role?.queryVar,
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      filters,
      orderBy,
    },
  });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <BeneficiariesListCS tasks={data?.tasks?.data} />
          <Divider />
          <PaginationComponent
            count={data?.tasks?.count}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
          />
        </>
      )}
    </>
  );
};
