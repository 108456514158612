import { FundClaculator } from "@/components/FundCalculator/FundClaculator";
import { RootState } from "@/shared/store";
import { FieldProps } from "@rjsf/utils";
import { useSelector } from "react-redux";

export const FundCalculatorWidget = (props: FieldProps) => {
  const { onChange, formData } = props;
  const { choices } = useSelector((state: RootState) => state.installment);

  return (
    <FundClaculator
      cards={choices}
      onChange={onChange}
      selectedOption={formData}
    />
  );
};
