/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { dataURItoBlob } from "@rjsf/utils";
export interface ExtractFileInfoReturn {
  name: string;
  size: number;
  type: string;
}

export function addNameToDataURL(
  dataURL: string | ArrayBuffer | null | undefined,
  name: string
): string {
  return dataURL?.replace(
    ";base64",
    `;name=${encodeURIComponent(name)};base64`
  );
}

export function extractFileInfo(dataURLs: Array<any>): ExtractFileInfoReturn[] {
  return dataURLs
    .filter((dataURL: any) => typeof dataURL !== "undefined")
    .map((dataURL: string): ExtractFileInfoReturn => {
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

export function processFile(file: File) {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (_: ProgressEvent<FileReader>) => {
      resolve({
        // dataURL: addNameToDataURL(event.target?.result, name),
        name,
        size,
        file,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
}

export function processFiles(files: FileList): any {
  return Promise.all([].map.call(files, processFile));
}
