import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { CssBaseline, responsiveFontSizes } from "@mui/material";
import { Toaster } from "react-hot-toast";

export const AppThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const lang = "ar";
  let theme = createTheme({
    direction: lang === "ar" ? "rtl" : "ltr",
    typography: { fontFamily: "Almarai, sans-serif", h1: { fontSize: "48px" } },
    spacing: 10,
    palette: {
      primary: {
        main: "#2C2C2C",
      },
      text: { primary: "#2C2C2C", secondary: "#6A6A6A" },
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: () => ({
          body: {
            backgroundColor: "#F7F7F7",
          },
          a: {
            textDecoration: "none",
            color: "initial",
          },
        }),
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "inherit",
            textDecoration: "none",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#FAFAFA",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            paddingBlock: 14,
          },
          head: {
            color: "#8C8C8C",
            fontWeight: "bold",
          },
        },
      },

      MuiContainer: {
        styleOverrides: {
          maxWidthLg: {
            "@media (min-width: 1200px)": {
              maxWidth: "1800px",
            },
            "@media (min-width: 2000px)": {
              maxWidth: "95%",
            },
          },
          maxWidthMd: {
            "@media (min-width: 1200px)": {
              maxWidth: "740px",
            },
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  // Create rtl cache
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: lang === "ar" ? [rtlPlugin] : [],
  });

  if (lang === "ar") {
    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toaster position="top-center" reverseOrder={true} />
          {children}
        </ThemeProvider>
      </CacheProvider>
    );
  } else {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  }
};
