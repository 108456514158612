import { Box, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useTranslation } from "react-i18next";

export const EmptyData = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <WarningAmberRoundedIcon
          sx={{ fontSize: "10rem", color: "primary.light" }}
        />
        <Typography fontWeight="bold" sx={{ fontSize: "2rem" }}>
          {t("No data")}
        </Typography>
      </Box>
    </Box>
  );
};
