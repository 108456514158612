import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { authLink, errorLink, uploadLink } from "./links";

export const graphQlAppClient = new ApolloClient({
  link: from([authLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});
