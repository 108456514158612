import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import lock from "@/assets/lock.svg";
import { NotFound } from "./NotFound";

export const UnAuthorized = ({ role }) => {
  const { t } = useTranslation();
  const redirectTo = role?.initialUrl;

  if (!role) return <NotFound />;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: "center", mt: 14 }}
    >
      <Box sx={{ p: 2, textAlign: "center", maxWidth: 600 }}>
        <Box component="img" src={lock} sx={{ mb: 2 }} />
        <Box sx={{ color: "#8C8C8C" }}>
          <Typography
            color="text.primary"
            variant="h6"
            fontWeight="bold"
            sx={{ mb: 2 }}
          >
            {t("You are not authorized")}
          </Typography>
          <Typography color="inherit">
            {t("You are logged in as ")} {role.display}
          </Typography>
          <Typography color="inherit">
            {redirectTo && (
              <NavLink
                to={redirectTo}
                style={{ color: "#328AF1", textDecoration: "underline" }}
              >
                {t("Click here")}
              </NavLink>
            )}{" "}
            {t("to go to your authorized route")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
