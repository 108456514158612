import { ReactNode } from "react";
import { AuthProvider as Provider } from "react-oidc-context";
import { AuthWrapper } from "./AuthWrapper";
import { WebStorageStateStore } from "oidc-client-ts";

const oidcConfig = {
  authority: import.meta.env.VITE_AUTHORITY,
  client_id: import.meta.env.VITE_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_REDIRECT_URI,
  scope: import.meta.env.VITE_SCOPE,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <Provider {...oidcConfig}>
        <AuthWrapper>{children}</AuthWrapper>
      </Provider>
    </div>
  );
};
