export const styles = (isNested: boolean) => ({
    background: "transparent",
    border: "none",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: 2.4,
    py: 2.6,
    borderRadius: 2,
    cursor: "pointer",
    "&": {
      boxShadow: isNested
        ? "0px 8px 9px -5px rgba(20, 20, 20, 0.06), 0px 8px 26px -4px rgba(20, 20, 20, 0.15)"
        : "",
    },
  });
  