import { Box, Button, Container, Grid, Typography } from "@mui/material";
import iconStatistics from "@/assets/icon-statistics.svg";
import st1 from "@/assets/st1.png";
import st2 from "@/assets/st2.png";
import st3 from "@/assets/st3.png";
import st4 from "@/assets/st4.png";
import st5 from "@/assets/st5.png";
import line from "@/assets/line.svg";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import { useAuth } from "react-oidc-context";
import { Footer } from "./Footer";
import { HomeAppbar } from "./HomeAppbar";

export const LandingPage = () => {
  const auth = useAuth();

  return (
    <div data-testid="LandingPage">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          height: "100%",
          minHeight: "100vh",
          pb: 5,
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #E9E9E9 100%)",
        }}
      >
        <HomeAppbar />
        <Box sx={{ textAlign: "center" }}>
          <Container maxWidth="md" sx={{}}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h1" fontWeight={400} sx={{ mb: 3 }}>
                منصة استشارات عقارية متكاملة
              </Typography>
              <Typography variant="h5" color="text.secondary" sx={{ mb: 3 }}>
                تساعدك في العثور على العقار المثالي عبر مؤشرات متخصصة وفريق عمل
                احترافي
              </Typography>
              <Button
                onClick={() => auth.signinRedirect()}
                variant="contained"
                data-testId="login-button"
                sx={{
                  maxWidth: "100%",
                  width: "334px",
                  py: 1,
                  fontWeight: "bold",
                }}
              >
                تسجيل الدخول
              </Button>
            </Box>
            <Grid container spacing={3} sx={{ mb: 5 }}>
              <Grid item xs={12} lg={4} display="flex" justifyContent="center">
                <Box display="flex" alignItems={"center"}>
                  <Box
                    sx={{
                      bgcolor: "#EDEDED",
                      width: 64,
                      height: 64,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      mr: 1,
                    }}
                  >
                    <Box component="img" src={iconStatistics} />
                  </Box>
                  <Typography fontWeight="bold">مؤشرات مالية</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4} display="flex" justifyContent="center">
                <Box display="flex" alignItems={"center"}>
                  <Box
                    sx={{
                      bgcolor: "#EDEDED",
                      width: 64,
                      height: 64,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      mr: 1,
                    }}
                  >
                    <CorporateFareRoundedIcon />
                  </Box>
                  <Typography fontWeight="bold">مؤشرات عقارية</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={4} display="flex" justifyContent="center">
                <Box display="flex" alignItems={"center"}>
                  <Box
                    sx={{
                      bgcolor: "#EDEDED",
                      width: 64,
                      height: 64,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "8px",
                      mr: 1,
                    }}
                  >
                    <FmdGoodOutlinedIcon />
                  </Box>
                  <Typography fontWeight="bold">مؤشرات مكانية</Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} lg={2.4}>
                <Box sx={{ height: "500px" }}>
                  <Typography fontWeight="bold" color="text.secondary">
                    1 &nbsp;&nbsp; استلام الطلب
                  </Typography>
                  <Box
                    component="img"
                    src={st1}
                    sx={{
                      position: "relative",
                      "&:before": {
                        content: "''",
                        display: "block",
                        position: "absolute",
                        background: "red",
                        height: "1px",
                        width: "120px",
                        backgroundImage: line,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <Box
                  sx={{ height: "500px" }}
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  flexDirection={"column"}
                >
                  <Typography fontWeight="bold" color="text.secondary">
                    2 &nbsp;&nbsp; تحديد الرغبات
                  </Typography>
                  <Box component="img" src={st2} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <Box sx={{ height: "500px" }}>
                  <Typography fontWeight="bold" color="text.secondary">
                    3 &nbsp;&nbsp; استعراض الخيارات العقارية المتاحة
                  </Typography>
                  <Box component="img" src={st3} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <Box
                  sx={{ height: "500px" }}
                  display="flex"
                  justifyContent="end"
                  alignItems="center"
                  flexDirection={"column"}
                >
                  <Typography fontWeight="bold" color="text.secondary">
                    4 &nbsp;&nbsp; بدء إجراءات إتمام الصفقة
                  </Typography>
                  <Box component="img" src={st4} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={2.4}>
                <Box sx={{ height: "500px" }}>
                  <Typography fontWeight="bold" color="text.secondary">
                    5 &nbsp;&nbsp; إتمام الصفقة
                  </Typography>
                  <Box component="img" src={st5} />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};
