import jsCookies from "js-cookie";

export const getFullToken = (): string | null => {
  const token = jsCookies.get("access_token");
  return token ? `Bearer ${token}` : null;
};

export const numberFormatter = (number: string | number) => {
  const formatter = new Intl.NumberFormat();
  if (number) {
    const modifiedNumber = Number(number);
    return formatter.format(modifiedNumber);
  } else if (number === "0" || number === 0) {
    return 0;
  } else {
    return "";
  }
};
