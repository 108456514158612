import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Box, Button, MenuItem, Modal, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { REPLACE_DEAL } from "@/shared/graphql/mutations";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { isNegotiator } from "@/shared/utils/mappers";
import { useParams } from "react-router";

export const ReplaceDeal = ({
  favoriteOfferId,
  setSelectedStep,
  refetchTask,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { taskId } = useParams();
  const canSubmit =
    userInfo?.isSuperuser || isNegotiator(userInfo) || userInfo?.isSuperuser;

  const [replaceDeal, { loading: mutationLoading }] = useMutation(REPLACE_DEAL);
  const onCreate = () => {
    replaceDeal({
      variables: {
        favoriteOfferInput: { taskId, favoriteOfferId },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Deal replaced successfully"));
        setSelectedStep((prev) => prev + 1);
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  return (
    <>
      <MenuItem onClick={handleOpen} sx={{ fontWeight: "bold" }}>
        {t("Replace offer")}
      </MenuItem>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign="center" variant="h6">
            {t("Replace offer in this deal")}
          </Typography>
          <Box display="flex" sx={{ mt: 3 }}>
            <Button
              onClick={onCreate}
              type="submit"
              variant="contained"
              disabled={!canSubmit || mutationLoading}
              sx={{ mx: 2 }}
              fullWidth
            >
              {t("Replace")}
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              sx={{ mx: 2 }}
              variant="outlined"
              fullWidth
            >
              {t("Cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
