import { Box, Collapse, Typography } from "@mui/material";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { styles } from "./AppCollapse.style";

export const AppCollapse = ({ title, children, isNested = false }) => {
  const [expand, setExpand] = useState(false);

  const expandClick = () => {
    setExpand((old) => !old);
  };

  return (
    <Box sx={{ bgcolor: "#fff", borderRadius: 2, mb: 2 }}>
      <Box component={"button"} sx={styles(isNested)} onClick={expandClick}>
        <Typography> {title}</Typography>

        {expand ? (
          <ExpandLess fontSize={"medium"} htmlColor="#A1A1A1" />
        ) : (
          <ExpandMore fontSize={"medium"} htmlColor="#A1A1A1" />
        )}
      </Box>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        <Box
          component="div"
          //   disablePadding
          sx={{ px: 2.4, pb: 2.8, pt: 2 }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
