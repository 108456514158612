export const beneficiaryOrderByFilters = [
  { label: "رقم المهمة", value: "id" },
  {
    label: "رقم التقنيات",
    value: "form_data__customer_service__beneficiaryData__ID_CODE",
  },
  {
    label: "الاسم",
    value: "form_data__customer_service__beneficiaryData__personalData__name",
  },
  {
    label: "رقم الجوال",
    value:
      "form_data__customer_service__beneficiaryData__personalData__mobileNumber",
  },
  {
    label: "المنطقة",
    value: "form_data__customer_service__beneficiaryData__location__region",
  },
  {
    label: "المدينة",
    value: "form_data__customer_service__beneficiaryData__location__city",
  },
  {
    label: "المنتج",
    value:
      "form_data__customer_service__beneficiaryData__supportPackages__product",
  },
  { label: "تاريخ البدء", value: "order__start_date" },
  { label: "تاريخ الانتهاء", value: "order__end_date" },
  {
    label: "مكالمات مجدولة",
    value:
      "form_data__customer_service__clientPreferences__negotiatorContactTime__contactDate",
  },
];

export const negotiatorOrderByFilters = [
  { label: "رقم المهمة", value: "id" },
  {
    label: "رقم التقنيات",
    value: "form_data__negotiator__negotiatorData__ID_CODE",
  },
  {
    label: "الاسم",
    value: "form_data__negotiator__negotiatorData__personalData__name",
  },
  {
    label: "رقم الجوال",
    value: "form_data__negotiator__negotiatorData__personalData__mobileNumber",
  },
  {
    label: "المنطقة",
    value: "form_data__negotiator__negotiatorData__location__region",
  },
  {
    label: "المدينة",
    value: "form_data__negotiator__negotiatorData__location__city",
  },
  {
    label: "المنتج",
    value: "form_data__negotiator__negotiatorData__supportPackages__product",
  },
  {
    label: "مكالمات مجدولة",
    value:
      "form_data__negotiator__negotiatorData__negotiatorContactTime__contactDate",
  },
  { label: "تاريخ البدء", value: "order__start_date" },
  { label: "تاريخ الانتهاء", value: "order__end_date" },
];
