/* eslint-disable react-refresh/only-export-components */
import { Routing } from "./shared/Routing";
import * as Sentry from "@sentry/react";
import "./index.css";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default Sentry.withProfiler(App);
