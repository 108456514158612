import { gql } from "@apollo/client";

export const GET_ALL_CUSTOMER_SERVICES = gql`
  query GetCustomerServices($pageInfo: PageInfo) {
    customerServices(pageInfo: $pageInfo) {
      data {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_CUSTOMER_SERVICES = gql`
  query GetCustomerServices($pageInfo: PageInfo) {
    customerServices(pageInfo: $pageInfo) {
      data {
        id
        email
        firstName
        lastName
        rejectedTasksCount
        totalTasksCount
        inProgressTasksCount
        finishedTasksCount
        pendingReviewTasksCount
      }
      count
    }
  }
`;
