/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Paper } from "@mui/material";

interface LargeImageViewProps {
  largeImageView: string;
  setLargeImageView: (src: any) => void;
}

const LargeImageView = ({
  largeImageView,
  setLargeImageView,
}: LargeImageViewProps) => {
  return (
    <Dialog
      maxWidth="md"
      open={!!largeImageView}
      onClose={() => setLargeImageView(null)}
    >
      <Paper style={{ padding: 10 }}>
        <img
          src={largeImageView}
          alt="any"
          style={{ margin: "auto", maxWidth: "100%" }}
        />
      </Paper>
    </Dialog>
  );
};

export default LargeImageView;
