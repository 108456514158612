import { gql } from "@apollo/client";

export const GET_ALL_NEGOTIATORS = gql`
  query {
    negotiators(pageInfo: {}) {
      data {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_NEGOTIATORS = gql`
  query getNegotiators($pageInfo: PageInfo) {
    negotiators(pageInfo: $pageInfo) {
      data {
        id
        email
        firstName
        lastName
        totalTasksCount
        rejectedTasksCount
        inProgressTasksCount
        finishedTasksCount
        pendingReviewTasksCount
      }
      count
    }
  }
`;
