import { ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import jsCookies from "js-cookie";
import { useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { setUser } from "@/shared/store/slices/authSlice";
import { useUserDetails } from "./useUserDetails";

export const AuthWrapper = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const router = useLocation();
  const {
    role,
    roles,
    isSuperuser,
    loading: userDetailsLoading,
  } = useUserDetails();

  const {
    error,
    isLoading,
    user: userData,
    isAuthenticated,
    // signinRedirect,
  } = useAuth();

  useEffect(() => {
    if (error) {
      console.error({ error });
    }

    if (!(isLoading || userDetailsLoading) && isAuthenticated && userData) {
      jsCookies.set("access_token", userData.access_token);

      if (router.pathname?.startsWith("/auth/callback")) {
        location.href = "/app";
      } else {
        const user = {
          email: userData?.profile?.email as string,
          firstName: userData?.profile?.first_name as string,
          lastName: userData?.profile?.last_name as string,
          phone: userData?.profile?.phone as string,
          role: role as any,
          roles,
          isSuperuser,
        };

        dispatch(
          setUser({ userToken: userData.access_token, userInfo: { ...user } })
        );
      }
    }
  }, [
    dispatch,
    error,
    isSuperuser,
    role,
    roles,
    isAuthenticated,
    isLoading,
    userDetailsLoading,
    router.pathname,
    userData,
  ]);

  return (
    <>
      {isLoading || userDetailsLoading ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      ) : (
        children
      )}
    </>
  );
};
