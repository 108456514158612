import {
  Box,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { t } from "i18next";

const NetworkAnalysis = ({ network_analysis }) => {
  return (
    <div>
      {Object.keys(network_analysis || {})?.length > 0 && (
        <Box>
          <Card>
            <TableContainer>
              <Table sx={{ minWidth: 200 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Tasks")}</TableCell>
                    <TableCell>{t("most_driving_distance")}</TableCell>
                    <TableCell>{t("most_driving_time")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(network_analysis || {})?.map(
                    ([key, value]: any[]) => {
                      if (["mosques", "parks", "schools"].includes(key)) {
                        return <></>;
                      }
                      return (
                        <TableRow>
                          <TableCell>{key.split("_")?.join(" ")}</TableCell>
                          <TableCell>{value?.most_driving_distance}</TableCell>
                          <TableCell>{value?.most_driving_time}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
      )}
    </div>
  );
};
export default NetworkAnalysis;
