import { Theme } from "@mui/material";

export const filterTagstyle = (
  theme: Theme,
  color: "primary" | "secondary" | "error" | "warning" | "info"
) => ({
  position: "relative",
  px: 1.3,
  py: 0.5,
  display: "inline-flex",
  alignItems: "center",
  borderRadius: 50,
  border: `1px solid ${theme.palette[color].main}`,
  "&:before": {
    content: "''",
    bgcolor: theme.palette[color].main,
    opacity: 0.1,
    width: "100%",
    position: "absolute",
    borderRadius: 50,
    height: "100%",
    top: -0.5,
    left: 1,
  },
});
