import { Box, Button, Modal } from "@mui/material";
import { Form } from "@rjsf/mui";
import { RJSFSchema } from "@rjsf/utils";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_CUSTOMER_SERVICES,
  GET_ALL_NEGOTIATORS,
} from "@/shared/graphql";
import assignNegotiatorSchema from "@/schemas/assign-negotiator.json";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";
import {
  REASSIGN_TO_CUSTOMER_SERVICE,
  REASSIGN_TO_NEGOTIATOR,
} from "@/shared/graphql/mutations";

export const ReAssignTask: FC<{
  taskId: number;
  refetch: () => void;
  assignedStatus: { key: number; display: string };
}> = ({ taskId, refetch, assignedStatus }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const [schema, setSchema] = useState({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [assignToNegotiator, { loading: mutationLoading }] = useMutation(
    REASSIGN_TO_NEGOTIATOR
  );

  const [assignToCustomerService, { loading }] = useMutation(
    REASSIGN_TO_CUSTOMER_SERVICE
  );

  useQuery(GET_ALL_NEGOTIATORS, {
    skip: assignedStatus.key !== 3,
    onCompleted: (res) => {
      const negotiatorsEnums = res?.negotiators?.data?.map((el: any) => +el.id);
      const negotiatorsEnumNames = res?.negotiators?.data?.map(
        (el: any) => `${el.firstName} ${el.lastName}`
      );
      const schema = assignNegotiatorSchema;
      schema.properties.userId.enum = negotiatorsEnums;
      schema.properties.userId.enumNames = negotiatorsEnumNames;
      setSchema(schema);
    },
  });

  useQuery(GET_ALL_CUSTOMER_SERVICES, {
    skip: assignedStatus.key !== 2,
    onCompleted: (res) => {
      const customerServicesEnums = res?.customerServices?.data?.map(
        (el: any) => +el.id
      );
      const customerServicesEnumNames = res?.customerServices?.data?.map(
        (el: any) => `${el.firstName} ${el.lastName}`
      );
      const schema = assignNegotiatorSchema;
      schema.properties.userId.enum = customerServicesEnums;
      schema.properties.userId.enumNames = customerServicesEnumNames;
      setSchema(schema);
    },
  });

  const onFormChange = (form) => {
    setFormData(form.formData);
  };

  const onSubmit = () => {
    if (assignedStatus.key === 3) {
      assignToNegotiator({
        variables: {
          taskInput: { tasksIds: [taskId], userId: formData?.userId },
        },
      })
        .then(() => {
          toast.success(t("Successfully Reassigned"));
          refetch();
          //   setSelectedTasks([]);
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
    }
    if (assignedStatus.key === 2) {
      assignToCustomerService({
        variables: {
          taskInput: { tasksIds: [taskId], userId: formData?.userId },
        },
      })
        .then(() => {
          toast.success(t("Successfully Reassigned"));
          refetch();
          //   setSelectedTasks([]);
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
    }
  };

  if (assignedStatus.key !== 3 && assignedStatus.key !== 2) return <></>;
  return (
    <>
      <Button
        sx={{ fontWeight: "bold" }}
        onClick={handleOpen}
        fullWidth
      >
        {t("Reassign")}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Form
            schema={schema as RJSFSchema}
            validator={validator}
            onChange={onFormChange}
            formData={formData}
            onSubmit={onSubmit}
          >
            <Box display="flex" sx={{ mt: 3 }}>
              <Button
                onClick={onSubmit}
                variant="contained"
                disabled={loading || mutationLoading}
                sx={{ mx: 2 }}
                fullWidth
              >
                {t("Assign")}
              </Button>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={loading || mutationLoading}
                sx={{ mx: 2 }}
                variant="outlined"
                fullWidth
              >
                {t("Cancel")}
              </Button>
            </Box>
          </Form>
        </Box>
      </Modal>
    </>
  );
};
