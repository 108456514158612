import { graphQlAppClient } from "../graphQlAppClient";
import {
  GET_ALL_CITIES,
  GET_ALL_DISTRICTS,
  GET_ALL_REGIONS,
  GET_ALL_ZONES,
} from "../queries/layers";

export async function fetchAllRegions() {
  const res = await graphQlAppClient.query({
    query: GET_ALL_REGIONS,
  });
  return res;
}

export async function fetchAllCitiesByRegionId(regionId: string) {
  const res = await graphQlAppClient.query({
    query: GET_ALL_CITIES,
    variables: { regionId },
  });
  return res;
}

export async function fetchAllZonesByRegionId(cityId: string) {
  const res = await graphQlAppClient.query({
    query: GET_ALL_ZONES,
    variables: { cityId },
  });
  return res;
}

export async function fetchAllDistricts(cityId: number, zonesId: number[]) {
  const res = await graphQlAppClient.query({
    query: GET_ALL_DISTRICTS,
    variables: { cityId, zonesId },
  });
  return res;
}
