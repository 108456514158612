import { FC, useEffect } from "react";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { Appbar } from "./Appbar";
import { Box, Container } from "@mui/material";

export const Layout: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Appbar />
        <Box sx={{ py: 3, height: "calc(100vh - 100px)" }} component="main">
          <Container sx={{ height: "100%" }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
};
