import React, { useEffect, useState } from "react";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import {
  DatePickerWidget,
  FileWidget,
  FundCalculatorWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GeometryField } from "@/components/RJSF/fields";
import BordersAndLengthsField from "@/components/RJSF/fields/BordersAndLengths/BordersAndLengthsField";
import UpDownWidget from "@/components/RJSF/widgets/UpDownWidget/UpDownWidget";
import { useMutation, useQuery } from "@apollo/client";
import { GET_OFFER_DATA, GET_SCHEMA_BY_KEY } from "@/shared/graphql";
import {
  CREATE_INTERNAL_OFFER,
  UPDATE_INTERNAL_OFFER,
} from "@/shared/graphql/mutations";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";
import { CoordinatesModal } from "./CoordinatesModal";

export const AddingOffer = ({ formMode }) => {
  const [formData, setFormData] = useState<any>({});
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const [lastCoordinates, setLastCoordinates] = useState();
  const [coordinatesData, setCoordinatesData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formKey = "internal_offer_schema";
  const { offerId } = useParams();

  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = JSON.parse(
        JSON.stringify(res.schemas.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;

      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });
  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const [createOffer, { loading: LoadingOffer }] = useMutation(
    CREATE_INTERNAL_OFFER
  );
  const [updateOffer, { loading: LoadingUpdateOffer }] = useMutation(
    UPDATE_INTERNAL_OFFER
  );

  const { loading: LoadingOfferData } = useQuery(GET_OFFER_DATA, {
    variables: { pk: offerId },
    onCompleted: async (res) => {
      const clonedFormData =
        res?.offers?.data?.at(0)?.data?.drafts ??
        res?.offers?.data?.at(0)?.data;
      setFormData(clonedFormData);
    },
  });

  const saveAsDraft = () => {
    const submittedData = formData;

    EditForm
      ? updateOffer({
          variables: {
            offerInput: {
              offerId: offerId,
              formSchemaKey: formKey,
              formData: JSON.stringify(formData),
              isDraft: true,
            },
          },
        })
          .then(() => {
            toast.success(t("Data has been saved successfully"));
            navigate("../tasks");
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          })
      : createOffer({
          variables: {
            offerInput: {
              formSchemaKey: formKey,
              formData: JSON.stringify(submittedData),
              isDraft: true,
            },
          },
        })
          .then(() => {
            navigate("../tasks");
            toast.success(t("data has been drafted"));
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          });
  };
  const EditForm = formMode === "edit";

  const onSubmit = (formData) => {
    const submittedData = formData.formData;

    EditForm
      ? updateOffer({
          variables: {
            offerInput: {
              offerId: offerId,
              formSchemaKey: formKey,
              formData: JSON.stringify(formData.formData),
              isDraft: false,
            },
          },
        })
          .then(() => {
            toast.success(t("Data has been saved successfully"));
            navigate("../tasks");
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          })
      : createOffer({
          variables: {
            offerInput: {
              formSchemaKey: formKey,
              formData: JSON.stringify(submittedData),
              isDraft: false,
            },
          },
        })
          .then(() => {
            toast.success(t("Data has been saved successfully"));
            navigate("../tasks");
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          });
  };

  const onFormChange = ({ formData }: any, fieldId) => {
    setFormData(formData);
    const currentCoordinates =
      formData?.completingOffer?.location?.coordinate?.coordinates;
    setLastCoordinates(currentCoordinates);

    if (fieldId === "root_completingOffer_location_coordinate") {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    if (coordinatesData) {
      const { reverse_geocoding } =
        (coordinatesData as any)?.offerLocationData?.data || {};

      if (reverse_geocoding) {
        const {
          city,
          district,
          national_address,
          parcel,
          plan,
          region,
          sub_zone,
          zone,
          summary,
        } = reverse_geocoding;

        setFormData((prev) => ({
          ...prev,
          completingOffer: {
            ...prev.completingOffer,
            location: {
              ...prev.completingOffer.location,
              coordinate: {
                ...prev.completingOffer.location.coordinate,
                coordinates: lastCoordinates,
              },
              nationalAddress: {
                city: city?.city_name || null,
                street: national_address?.street_arabic_name || null,
                district: district?.district_name || null,
                postalCode: national_address?.zip_code || undefined,
                shortAddress: national_address?.short_address || null,
                buildingNumber: national_address?.building_number || undefined,
                secondaryNumber:
                  national_address?.additional_number || undefined,
              },
              areaName: region?.region_name || null,
              planNumber: plan?.plan_number || undefined,
              plotNumber: parcel?.parcel_number || undefined,
              subdivision: sub_zone?.sub_zone_name || null,
              mainDivision: zone?.zone_name || null,
              summary: summary || null,
            },
          },
        }));
      }

      setOpenModal(false);
    }
  }, [coordinatesData]);

  return (
    <>
      <CoordinatesModal
        setCoordinatesData={setCoordinatesData}
        selectedCoordinates={lastCoordinates}
        open={openModal}
        setOpen={setOpenModal}
      />{" "}
      <Box
        sx={{
          width: "100%",
          p: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          sx={{
            width: "70%",
          }}
        >
          <Form
            formContext={{
              onFileChange,
            }}
            schema={formSchema || {}}
            uiSchema={uiSchema}
            validator={validator}
            showErrorList={false}
            formData={formData}
            onChange={onFormChange}
            experimental_defaultFormStateBehavior={{
              allOf: "populateDefaults",
            }}
            noHtml5Validate
            transformErrors={(errors) => {
              const modfiedErrors = errors?.map((err) => {
                if (
                  err.name === "required" ||
                  err.name === "minItems" ||
                  err.name === "type"
                ) {
                  return { ...err, message: "حقل مطلوب" };
                }
                if (err.name === "enum") {
                  return {
                    ...err,
                    message: "يرجى الإختيار من القيم الموجودة",
                  };
                }
                if (err.name === "if") {
                  return {
                    ...err,
                    message: "",
                  };
                }
                return err;
              });
              return modfiedErrors;
            }}
            widgets={{
              FileWidget,
              YesNoWidget,
              TextWidget,
              SelectWidget,
              DateWidget: DatePickerWidget,
              UpDownWidget,
            }}
            fields={{
              FundCalculatorWidget: FundCalculatorWidget as any,
              GeometryField: GeometryField as any,
              BordersAndLengthsField: BordersAndLengthsField as any,
            }}
            templates={{ ObjectFieldTemplate }}
            onSubmit={onSubmit}
          >
            <Button
              onClick={saveAsDraft}
              variant="contained"
              sx={{ mt: 2, mr: 1 }}
              disabled={
                schemaLoading ||
                LoadingOffer ||
                LoadingUpdateOffer ||
                LoadingOfferData
              }
            >
              {t("save and complete later")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
              disabled={
                schemaLoading ||
                LoadingOffer ||
                LoadingUpdateOffer ||
                LoadingOfferData
              }
            >
              {t("save")}
            </Button>
          </Form>
        </Grid>
      </Box>
    </>
  );
};
