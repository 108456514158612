import { gql } from "@apollo/client";

export const GET_ALL_FAVOURITE_OFFERS = gql`
  query favoriteOffers($taskId: Int!) {
    favoriteOffers(taskId: $taskId) {
      count
      data {
        hasDeal
        offer {
          offerData {
            address
            establishmentType
            offerType
            realEstatePrice
          }
          id
          licenseNumber
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          offerType
          reservationRemainingHours
          status
        }
        id
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query getAllOffers(
    $offset: BoundedInt
    $limit: BoundedInt
    $orderBy: String
    $taskId: Int
  ) {
    offers(
      pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }
      taskId: $taskId
    ) {
      count
      geometries
      boundaries
      data {
        offerData {
          establishmentType
          address
          realEstatePrice
          offerType
        }
        negotiators {
          count
          data {
            firstName
            lastName
          }
        }
        licenseNumber
        favoriteOfferId
        id
        offerType
        reservationRemainingHours
        status
      }
    }
  }
`;
export const GET_OFFER_DATA = gql`
  query getOfferData($pk: Int) {
    offers(pk: $pk) {
      count
      data {
        data
      }
    }
  }
`;

export const GET_COORDINATES_DATA = gql`
  query offerLocationData($longitude: Float!, $latitude: Float!) {
    offerLocationData(longitude: $longitude, latitude: $latitude) {
      data
    }
  }
`;
