import { Box, Typography } from "@mui/material";
import notFound from "@/assets/404.svg";
import { t } from "i18next";

export const NotFound = () => {
  return (
    <Box
      sx={{ textAlign: "center", height: "100%" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Box component="img" src={notFound} sx={{ mb: 3.2 }} alt="not found" />
        <Typography sx={{ fontSize: "32px" }} variant="h5" fontWeight="bold">
          {t("This page is not found")}
        </Typography>
      </Box>
    </Box>
  );
};
