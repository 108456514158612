import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { Input, FormHelperText, Button, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import JSONPath from "jsonpath";

/**
 * JSON path query
 *
 * @doc: https://github.com/dchester/jsonpath
 */

export const UpDownWidget = ({
  id,
  required,
  readonly,
  disabled,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  uiSchema,
  formContext: { allFormData },
}: WidgetProps) => {
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const buttonClicked = (value: number) => {
    onChange(value);
  };

  const castValue =
    typeof value === "string" && !isNaN(Number(value))
      ? Number(value)
      : value || 0;

  const relatedArrayField = uiSchema?.["ui:relatedArrayField"];
  const relatedArrayFieldItemName = uiSchema?.["ui:relatedArrayFieldItemName"];
  const relatedArrayFieldCount =
    relatedArrayField &&
    JSONPath.query(allFormData, relatedArrayField)[0]?.length;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #dfdfdf",
        borderRadius: 1.25,
        backgroundColor: "#fff",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          width: "60%",
          padding: "16px 12px",
          fontWeight: "bold",
          color: "#16151a",
          borderRight: "1px solid #dfdfdf",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "40%",
          padding: "0 8px",
        }}
      >
        <Button
          disableElevation
          color="primary"
          variant="contained"
          sx={{
            padding: 0,
            minWidth: 28,
            height: 28,
            borderRadius: 1.25,
          }}
          disabled={disabled || readonly}
          onClick={() => buttonClicked(castValue + 1)}
        >
          <AddIcon />
        </Button>

        <Input
          id={id}
          autoFocus={autofocus}
          required={required}
          disableUnderline
          type="number"
          value={castValue}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          disabled={disabled || readonly}
          inputProps={{
            sx: {
              textAlign: "center",
              padding: 0,
              width: 50,
              "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&[type=number]": {
                MozAppearance: "textfield",
              },
            },
          }}
          sx={{
            width: 60,
            margin: "0 8px",
            border: "none",
          }}
        />

        <Button
          disableElevation
          color="secondary"
          variant="contained"
          sx={{
            padding: 0,
            minWidth: 28,
            height: 28,
            borderRadius: 1.25,
          }}
          disabled={disabled || readonly || castValue <= 0}
          onClick={() => buttonClicked(castValue - 1)}
        >
          <RemoveIcon />
        </Button>
      </Box>

      {relatedArrayField && castValue > 0 && (
        <FormHelperText
          sx={{
            paddingTop: 0.8,
            fontSize: "0.875rem",
            color: "text.secondary",
          }}
        >
          <span>لقد تم إضافة </span>
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {relatedArrayFieldCount || 0}
          </Typography>
          <span> {relatedArrayFieldItemName} </span>
          <span>من أصل </span>
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            {castValue}
          </Typography>
        </FormHelperText>
      )}
    </Box>
  );
};

export default UpDownWidget;
