import { WidgetProps } from "@rjsf/utils";
import clsx from "clsx";
import { isObject } from "../../utils/t";
import { Autocomplete, Chip, TextField } from "@mui/material";

export interface ISelectWidgetOption {
  label: string;
  value: string;
}

const getDisplayedLabel = (option: ISelectWidgetOption): string =>
  option?.label ??
  option?.value ??
  (isObject(option) ? JSON.stringify(option) : option);

const getSelectedValue = (option: ISelectWidgetOption): string =>
  option?.value ?? option;

const getDisplayedValue = (value: any, isMultiple: boolean) =>
  typeof value === "undefined"
    ? isMultiple
      ? []
      : ""
    : isMultiple
    ? (value as ISelectWidgetOption[])?.map((option) =>
        getSelectedValue(option)
      ) ?? []
    : getSelectedValue(value);

export const SelectWidget = ({
  id,
  value,
  label,
  schema,
  required,
  disabled,
  readonly,
  multiple,
  autofocus,
  onChange,
  rawErrors,
  //   placeholder,
  oneBorder = false,
  options: { enumOptions },
  uiSchema: uiSchemaProp,
}: WidgetProps) => {
  const uiSchema = uiSchemaProp || {};
  const isMultiple = multiple || uiSchema["ui:multiple"] || false;

  const _onChange = (_: any, value: any) =>
    onChange(getDisplayedValue(value, isMultiple));

  const displayedValue = getDisplayedValue(value, isMultiple);

  const _menuOptions = (enumOptions ?? []) as ISelectWidgetOption[];

  const menuOptions = isMultiple
    ? _menuOptions.filter(
        (option) => !displayedValue.includes(getSelectedValue(option))
      )
    : _menuOptions;

  return (
    <Autocomplete
      id={id}
      fullWidth
      size="small"
      value={value}
      freeSolo
      autoSelect
      //   placeholder={placeholder || ""}
      key={JSON.stringify(enumOptions)} // Force re-render when options change
      disabled={disabled || readonly}
      options={menuOptions}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getOptionLabel={(option: ISelectWidgetOption) =>
        // getDisplayedLabel(option)
        option.label ?? option ?? ""
      }
      onChange={_onChange}
      multiple={isMultiple}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            // key={index}
            variant="outlined"
            label={getDisplayedLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      noOptionsText="لا يوجد اختيارات لمحدد البحث"
      renderInput={(params) => (
        <TextField
          {...params}
          variant={oneBorder ? "standard" : "outlined"}
          required={required}
          autoFocus={autofocus}
          // value={displayedValue}
          label={
            !oneBorder ? uiSchema["ui:label"] || label || schema.title : ""
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //   @ts-ignore
          error={rawErrors?.length > 0}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            sx: { backgroundColor: "#fff", padding: "10px 12px !important" },
            className: clsx((params?.inputProps as any)?.className),
          }}
        />
      )}
    />
  );
};
