import { Link } from "@mui/material";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const AppbarNavLink: FC<{
  to: string;
  children: React.ReactNode;
}> = ({ to, children }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to);

  return (
    <Link
      to={to}
      sx={{
        fontWeight: "bold",
        color: !isActive ? "text.secondary" : "inherit",
        mr: 3,
      }}
      component={NavLink}
    >
      {children}
    </Link>
  );
};
