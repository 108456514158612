/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useMemo, useState } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { WidgetProps } from "@rjsf/utils";
import { FilesPreview } from "./FilesPreview";
import { useTranslation } from "react-i18next";
import { processFiles } from "../../utils/utils";

const InputComponent = (props: any) => {
  const { multiple, id, readonly, disabled, autofocus, options, onChange } =
    props;

  return (
    <input
      style={{
        position: "absolute",
        left: 0,
        width: "100%",
        top: 0,
        opacity: 0,
        height: "100%",
      }}
      type="file"
      disabled={readonly || disabled}
      id={id}
      multiple={multiple}
      defaultValue=""
      autoFocus={autofocus}
      onChange={onChange}
      accept={options.accept}
    />
  );
};

export const FileWidget = (props: WidgetProps) => {
  const {
    multiple,
    readonly,
    disabled,
    label,
    uiSchema = {},
    // schema,
    value,
    formContext,
    onChange,
  } = props;

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //   const title = uiSchema['ui:title'] ?? schema.title;

  const files = useMemo(() => {
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  }, [value]);

  const _onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      const processedFiles = await processFiles(event.target.files as any);
      const onFileChange = formContext.onFileChange;
      if (onFileChange) {
        const response = (await onFileChange(processedFiles)) as Array<string>;
        if (multiple) {
          onChange([...files, ...response]);
        } else {
          onChange(response[0]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteHandler = (index: number) => {
    let _values: any = [...files];
    if (multiple) {
      _values.splice(index, 1);
    } else {
      _values = undefined;
    }
    onChange(_values);
  };

  const uploadMoreInput = () => {
    return <InputComponent {...props} onChange={_onChange} />;
  };

  return (
    <Box sx={{ position: "relative" }}>
      {/* <Title title={title} /> */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
          mt: 3,
          minHeight: 200,
          flexDirection: "column",
          borderRadius: 2,
          border: 1,
          borderColor: "#e0e0e0",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,0.7)",
            zIndex: 9,
            display: loading ? "flex" : "none",
          }}
        >
          <CircularProgress />
        </Box>
        {!!files.length || <AddIcon />}
        {!files.length && (
          <Typography sx={{ textAlign: "center", mt: 2, width: "100%" }}>
            {uiSchema["ui:placeholder"] || label || t("upload_attachment")}
          </Typography>
        )}
        <FilesPreview
          readonly={readonly || disabled}
          files={files}
          onDelete={readonly || disabled ? undefined : deleteHandler}
          onChange={onChange}
          renderInputComponent={
            multiple && files.length && !(readonly || disabled)
              ? uploadMoreInput
              : undefined
          }
        />
        {!files.length && <InputComponent {...props} onChange={_onChange} />}
      </Box>
    </Box>
  );
};
