import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FavouriteOffersActions } from "./FavouriteOffersActions";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_FAVOURITE_OFFERS,
  GET_SCHEMA_BY_KEY,
  GET_SIGNLE_TASK,
} from "@/shared/graphql";
import { useParams } from "react-router";
import { Loading } from "@/components/Loading";
import { OffersEmptyState } from "@/components/OffersEmptyState";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import {
  DatePickerWidget,
  FileWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { UPDATE_NEGOTIATOR_TASK } from "@/shared/graphql/mutations";
import { graphqlErrorHandler } from "@/shared/utils";
import toast from "react-hot-toast";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";

export const FavouriteOffers = ({ setSelectedStep, refetchTask }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({
    chooseSuitableProperty: {
      isOwnerNegotiated: "لا",
      isPropertyInspected: "لا",
      isPropertySentToBeneficiary: "لا",
      suitablePropertyFounded: "لا",
    },
  });
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const formKey = "choose_suitable_property";
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { taskId } = useParams();
  const {
    data,
    refetch: refetchFavOffers,
    loading,
    error,
  } = useQuery(GET_ALL_FAVOURITE_OFFERS, {
    variables: { taskId },
  });
  const [updateTask, { loading: mutationLoading }] = useMutation(
    UPDATE_NEGOTIATOR_TASK
  );
  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    onCompleted: async (res) => {
      const schema = JSON.parse(
        JSON.stringify(res.schemas.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema.UISchema;
      setUiSchema(fetchedUiSchema);
      setFormSchema(fetchedFormSchema);
    },
  });
  const { loading: taskLoading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      const placeOrderFormData =
        res?.tasks?.data?.at(0)?.formData?.negotiator?.placeOrder;
      if (placeOrderFormData) {
        setFormData((prev) => ({ ...prev, ...placeOrderFormData }));
        return;
      }
    },
  });

  const onFormChange = async (form: any) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));

    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  if (loading) return <Loading />;
  if (error) return <></>;

  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };
  const favoriteOffers = data?.favoriteOffers?.data;
  const taskHasDeal = favoriteOffers?.some((offer) => offer.hasDeal);

  const onSubmit = (form) => {
    const submittedData = { placeOrder: form.formData };
    let submittedStatus: string | null = null;
    if (
      form.formData.chooseSuitableProperty.suitablePropertyFounded === "نعم"
    ) {
      submittedStatus = "LOOKING_FOR_PROPERTY";
    }
    if (
      form.formData.chooseSuitableProperty.isPropertySentToBeneficiary === "نعم"
    ) {
      submittedStatus = "SEND_PROPERTY_TO_BENEFICIARY";
    }
    if (form.formData.chooseSuitableProperty.isPropertyInspected === "نعم") {
      submittedStatus = "PROPERTY_INSPECTION";
    }
    if (form.formData.chooseSuitableProperty.isOwnerNegotiated === "نعم") {
      submittedStatus = "NEGOTIATE_WITH_OWNER";
    }
    if (form.formData.chooseSuitableProperty.suitablePropertyFounded === "لا") {
      submittedStatus = "SECOND_LEVEL_PREFERENCES";
    }
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
          status: submittedStatus,
        },
      },
    })
      .then(() => {
        refetchTask();
        toast.success(t("Data has been saved successfully"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  return (
    <>
      {favoriteOffers.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
              {t("Favourite offers")}
            </Typography>
            <Card sx={{ mb: 2 }} variant="outlined">
              <TableContainer>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Offer Id")}</TableCell>
                      <TableCell>{t("Property type")}</TableCell>
                      <TableCell>{t("Offer type")}</TableCell>
                      <TableCell>{t("Address")}</TableCell>
                      <TableCell>{t("Price")}</TableCell>
                      <TableCell>{t("Offer Status")}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {favoriteOffers?.map((offer) => {
                      return (
                        <TableRow>
                          <TableCell>{offer?.offer?.id}</TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.establishmentType ??
                              "---"}
                          </TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.offerType?.map(
                              (item, index) => (
                                <div key={index}>{item ?? "---"}</div>
                              )
                            )}
                          </TableCell>
                          <TableCell>
                            {formatAddress(offer?.offer?.offerData?.address) ??
                              "---"}
                          </TableCell>
                          <TableCell>
                            {offer?.offer?.offerData?.realEstatePrice ?? "---"}
                          </TableCell>

                          <TableCell>
                            <Chip label={t(offer?.offer?.status?.display)} />
                          </TableCell>
                          <TableCell>
                            <FavouriteOffersActions
                              taskHasDeal={taskHasDeal}
                              refetchTask={refetchTask}
                              refetchFavOffers={refetchFavOffers}
                              favoriteOfferId={offer.id}
                              hasDeal={offer?.hasDeal}
                              setSelectedStep={setSelectedStep}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Form
              schema={formSchema || {}}
              uiSchema={uiSchema || {}}
              validator={validator}
              onChange={onFormChange}
              formData={formData}
              noHtml5Validate
              showErrorList={false}
              transformErrors={(errors) => {
                const modfiedErrors = errors?.map((err) => {
                  if (
                    err.name === "required" ||
                    err.name === "minItems" ||
                    err.name === "type"
                  ) {
                    return { ...err, message: "حقل مطلوب" };
                  }
                  if (err.name === "enum") {
                    return {
                      ...err,
                      message: "يرجى الإختيار من القيم الموجودة",
                    };
                  }
                  if (err.name === "if") {
                    return {
                      ...err,
                      message: "",
                    };
                  }
                  return err;
                });
                return modfiedErrors;
              }}
              widgets={{
                FileWidget,
                YesNoWidget,
                SelectWidget,
                TextWidget,
                DateWidget: DatePickerWidget,
              }}
              templates={{ ObjectFieldTemplate }}
              onSubmit={onSubmit}
            >
              {/* <Button
          onClick={saveAsDraft}
          variant="contained"
          sx={{ mt: 2, mr: 1 }}
          disabled={mutationLoading}
        >
          {t("save and complete later")}
        </Button> */}
              <Button
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
                disabled={taskLoading || schemaLoading || mutationLoading}
              >
                {t("save")}
              </Button>
            </Form>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: "20px", py: 2 }}>
              {t("Favourite offers")}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "100%",
              height: "100%",
              p: 7,
            }}
          >
            <OffersEmptyState />
          </Box>
        </Box>
      )}
    </>
  );
};
