/* eslint-disable @typescript-eslint/no-explicit-any */
import { WidgetProps, schemaRequiresTrueValue } from '@rjsf/utils';
import { FormLabel, Button, Box } from '@mui/material';
import { checkCallback } from '@/shared/utils/checkCallback';

interface IYesNoWidgetProps extends WidgetProps {
  labelHide?: boolean;
  fullWidth?: boolean;
  isActivelyGreen?: (value: any) => any;
}

export const YesNoWidget = ({
  schema,
  id,
  options,
  value,
  disabled,
  readonly,
  label,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  labelHide,
  fullWidth,
  isActivelyGreen,
}: IYesNoWidgetProps) => {

  const onClickOption = (checked: boolean) => onChange(checked);

  const { enumOptions }: any = options;
  const required = schemaRequiresTrueValue(schema);
  const castValue = value === undefined ? false : value;

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 2
    }}>
      {!labelHide && (label || schema.title) && (
        <FormLabel sx={{
          color: 'text.primary'
        }} required={required} htmlFor={id}>
          {label || schema.title}
        </FormLabel>
      )}

      <Box sx={{
        display: 'flex',
        flexDirection: fullWidth ? 'column' : 'row',
        overflow: 'hidden',
        backgroundColor: 'background.paper',
        borderRadius: 1.2,
        flexGrow: 1
      }}>
        {(enumOptions || []).map((option: any, index: number) => (
          <Button
            key={index}
            type="button"
            sx={{
              border: 'none',
              cursor: 'pointer',
              padding: 1.4,
              color: (theme) => option.value === castValue ? theme.palette.common.white : 'text.primary',
              backgroundColor: (theme) => option.value === castValue ? theme.palette.primary.main : 'transparent',
              '&:hover': {
                backgroundColor: (theme) => option.value === castValue ? theme.palette.primary.dark : theme.palette.action.hover
              },
              ...(checkCallback(isActivelyGreen, [option]) && {
                backgroundColor: '#398f00'
              })
            }}
            disabled={disabled || readonly}
            autoFocus={autofocus && option.value === castValue}
            onClick={() => onClickOption(option.value)}
            onBlur={() => onBlur(id, option.value)}
            onFocus={() => onFocus(id, option.value)}
          >
            {option.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
