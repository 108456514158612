// ADMIN = 1, _("Admin")
// PROJECT_MANAGERS = 2, _("Project Managers")
// CUSTOMER_SERVICES = 3, _("Customer Services")
// NEGOTIATORS = 4, _("Negotiator")

export const rolesMap = {
  2: {
    initialUrl: "/app/project-manager/beneficiaries",
    queryVar: "PROJECT_MANAGERS",
    links: [
      { to: "/app/project-manager/beneficiaries", text: "Beneficiaries" },
      { to: "/app/project-manager/orders", text: "Orders" },
      { to: "/app/project-manager/customer-service", text: "Customer Service" },
      { to: "/app/project-manager/negotiators", text: "Negotiators" },
    ],
  },
  3: {
    initialUrl: "/app/customer-service/tasks",
    queryVar: "CUSTOMER_SERVICES",
    links: [{ to: "/app/customer-service/tasks", text: "Tasks" }],
  },
  4: {
    initialUrl: "/app/negotiator/tasks",
    queryVar: "NEGOTIATORS",
    links: [{ to: "/app/negotiator/tasks", text: "Tasks" }],
  },
};

// IN_PROGRESS = 1, _("In Progress")
// REJECTED = 3, _("Rejected")
// PENDING_REVIEW = 4, _("Pending Review")
// FINISHED = 5, _("Finished")
// CHECK_BENEFICIARY_DATA = 6, _("Checking Beneficiary Data")
// REVIEW_RECOMMENDATIONS = 7, _("Review Recommendations")
// REVIEW_OFFERS = 8, _("Review Offers")
// CREATE_DEAL = 9, _("Create Deal")

// (NOT_ASSIGNED = 1), _("Not Assigned");
// (ASSIGNED_TO_CUSTOMER_SERVICE = 2), _("Assigned To Customer Service");
// (ASSIGNED_TO_NEGOTIATOR = 3), _("Assigned To Negotiator");
// (ASSIGNED_TO_PROJECT_MANAGER = 5), _("Assigned To Project Manager");

export const statusColorMap = {
  "1": { color: "#533607", background: "#F6D8AB" },
  "3": { color: "#4E2A7F", background: "#E4D5F7" },
  "4": { color: "#235935", background: "#C2FCD6" },
  "5": { color: "#7F4C29", background: "#F6D7C1" },
  "6": { color: "#328AF1", background: "#cee5ff" },
  "7": { color: "#328AF1", background: "#cee5ff" },
  "8": { color: "#328AF1", background: "#cee5ff" },
  "9": { color: "#235935", background: "#C2FCD6" },
  "10": { color: "#8B1D3D", background: "#FBD1D9" },
  "11": { color: "#3B4A6B", background: "#DDE3F0" },
  "12": { color: "#6B8E23", background: "#E9F7C9" },
  "13": { color: "#D2691E", background: "#FAD7C4" },
  "14": { color: "#2F4F4F", background: "#D3E7E6" },
  "15": { color: "#A020F0", background: "#EED8FF" },
  "16": { color: "#B22222", background: "#FDDADA" },
  "17": { color: "#556B2F", background: "#DFF0BF" },
  "18": { color: "#5A2A27", background: "#F6B2B2" },
  "19": { color: "#000000", background: "#7DCB34" },
};

export const isProjectManager = (userInfo) => {
  return userInfo?.role?.key === 2;
};

export const isCustomerService = (userInfo) => {
  return userInfo?.role?.key === 3;
};

export const isNegotiator = (userInfo) => {
  return userInfo?.role?.key === 4;
};
