import React, { useState } from "react";
import { WidgetProps } from "@rjsf/utils";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";

export const DatePickerWidget = ({
  label,
  value,
  schema,
  id,
  uiSchema,
  disabled,
  rawErrors,
  onBlur,
  onFocus,
  readonly,
  autofocus,
  onChange,
}: WidgetProps) => {
  const [open, setOpen] = useState(false);

  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);

  const momentValue = value ? moment(value, "YYYY-MM-DD") : null;

  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const disableFuture = uiSchema?.["ui:disableFuture"] || false;
  const disablePast = uiSchema?.["ui:disablePast"] || false;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        views={["year", "month", "day"]}
        autoFocus={autofocus}
        value={momentValue}
        format="YYYY-MM-DD"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(date) => {
          const formattedDate = moment(date).format("YYYY-MM-DD");
          onChange(formattedDate);
        }}
        disabled={disabled || readonly || uiSchema?.["ui:disabled"]}
        disableFuture={disableFuture}
        disablePast={disablePast}
        label={uiSchema?.["ui:label"] || label || schema.title}
        slots={{ textField: TextField, openPickerIcon: CalendarIcon }}
        slotProps={{
          textField: {
            variant: "outlined",
            error: rawErrors && rawErrors?.length > 0,
            onBlur: _onBlur,
            onFocus: _onFocus,
            onClick: () => setOpen(true),
          },
        }}
      />
    </LocalizationProvider>
  );
};
