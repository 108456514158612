import { useSearchParams } from "react-router-dom";

export const useSorting = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const orderBy = (field: string) => {
    setSearchParams((params) => {
      if (searchParams.get("orderBy") === field) {
        params.set("orderBy", `-${field}`);
      } else {
        params.set("orderBy", `${field}`);
      }
      return params;
    });
  };
  const sortingValue = searchParams.get("orderBy");

  // toggle the current orderBy value whether asc or desc
  const toggleSorting = () => {
    if (sortingValue) {
      if (sortingValue?.includes("-")) {
        orderBy(sortingValue.slice(1));
      } else {
        orderBy(`-${sortingValue}`);
      }
    }
  };
  return {
    orderBy,
    sortingValue,
    toggleSorting,
  };
};
