import { Button } from "@mui/material";

export const TaskSidebarButton = (props) => {
  const { active, children, ...rest } = props;
  return (
    <Button
      fullWidth
      sx={{
        mb: 1.6,
        pl: 2,
        py: 1,
        borderRadius: "4px",
        justifyContent: "start",
        color: active ? "initial" : "#8C8C8C",
        fontWeight: "bold",
        bgcolor: active ? "#EBEBEB" : "initial",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
