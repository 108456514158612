import { useQuery } from "@apollo/client";
import { GET_USER_DETAILS } from "../graphql";
import { rolesMap } from "../utils/mappers";

export const useUserDetails = () => {
  const { data, ...rest } = useQuery(GET_USER_DETAILS);
  const roles = data?.userDetails?.roles?.map((role) => ({
    ...role?.role,
    ...rolesMap[role?.role?.key],
  }));

  // Retrieve the selected role from localStorage
  const roleKey = localStorage.getItem("roleKey");
  let selectedRole = roles?.find((role) => role.key.toString() === roleKey);

  // Update the selected role if it doesn't exist in the user details
  if (data && !selectedRole) {
    selectedRole = roles?.at(0);
    localStorage.setItem("roleKey", JSON.stringify(selectedRole?.key) || "");
  }

  const role = selectedRole;
  const isSuperuser = data?.userDetails?.isSuperuser;

  return {
    role,
    roles,
    isSuperuser,
    userDetails: data?.userDetails,
    ...rest,
  };
};
