import { FC } from "react";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import { FilterTagProps } from "./FilterTag.interface";
import { useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { filterTagstyle } from "./FilterTag.style";

export const FilterTag: FC<FilterTagProps> = ({
  field,
  text,
  value,
  displayedvalue,
  color = "primary",
  showCloseButton = true,
}) => {
  const [, setSearchParams] = useSearchParams();

  const removeFilterField = () => {
    setSearchParams((params) => {
      const allValues = params.getAll(field) || [];
      const updatedValues = allValues.filter((paramVal) => paramVal !== value);
      params.delete(field);
      updatedValues.forEach((val) => {
        params.append(field, val);
      });
      return params;
    });
  };

  return (
    <Box sx={(theme: Theme) => filterTagstyle(theme, color)}>
      <Typography sx={{ fontSize: "small" }} fontWeight="bold">
        {text}&nbsp;
      </Typography>
      <Typography
        component="span"
        sx={(theme: Theme) => ({
          color: theme.palette[color].main,
          fontSize: "small",
        })}
        fontWeight="bold"
      >
        {displayedvalue}
      </Typography>
      {field === "delete-all"}
      {showCloseButton && (
        <IconButton size="small" sx={{ p: 0, ml: 0.4 }}>
          <CloseIcon fontSize="small" onClick={removeFilterField} />
        </IconButton>
      )}
    </Box>
  );
};
